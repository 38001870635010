import { FC } from 'react'
import CN from 'classnames'
import { Button, Rating } from 'components/atoms'
import AI from 'assets/images/AI.png'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'

export interface AppDetailsCardProps {
  appId?: string
  appImage?: string | undefined | null
  appName?: string | undefined
  className?: string | undefined
  noOfViews?: number | undefined
  
  onDeleteClick?: any
  onEditClick?: any
  rating?: number | undefined
  average_rating?: number | undefined
  c1?: string[] 
  c1Name?: string 
  c2?: string[]
  c2Name?: string 
  c3?: string[]
  c3Name?: string 
  c4?: string[]
  c4Name?: string 
  onCardClick?: any
  status?: string
  [x: string]: any
}

export const AppDetailsCard: FC<AppDetailsCardProps> = ({
  className,
  appId,
  appImage = undefined,
  appName,
  average_rating,
  noOfViews = 0,
  onDeleteClick,
  onEditClick,
  rating = 0,
  c1 = [],
  c2 = [],
  c3 =[],
  c4 =[],
  c1Name,
  c2Name,
  c3Name,
  c4Name,
  onCardClick,
  status,
}: AppDetailsCardProps) => {
  const cardClassnames = CN(
    'flex flex-col items-start justify-between w-full h-[75vh] lg:h-[84vh] bg-white poppins-medium px-5 lg:pt-[20px] lg:pb-[12px] pt-[16px] pb-[4px] rounded-[30px] shadow-md bg-opacity-70 cursor-pointer mb-2',
    {' opacity-50': status !== 'true'},
    className,
  )
  return (
    <div className={cardClassnames} onClick={onCardClick}>
      <div className="w-full flex flex-col justify-start items-start overflow-hidden pb-2">

        <div className='flex w-full h-fit pb-5'>
          <img src={appImage || AI} alt='img-app' className='lg:w-[116px] lg:h-[116px] w-[85px] h-[85px] rounded-full border-[8px] border-[#9C9C9C]'/>
          <div className='flex flex-col pl-5 capitalize'>
            <div className='poppins-medium text-[20px] leading-tight min-h-[50px]'>{appName}</div>
            <div className='w-full flex pt-2'>
              <Rating selectedRating={average_rating} defaultValue={average_rating} totalStars={5}/>
            </div>
            <div className='flex justify-center items-center poppins-bold text-[12px] leading-none px-4 py-1 rounded-full bg-white w-fit mt-2'>
              ID: {appId}
            </div>
          </div>
        </div>

          <div className="w-full h-[110px] min-h-[100px] flex flex-col justify-start items-start bg-white rounded-[10px] p-4">
            <div className="flex justify-start items-center h-fit w-full relative">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full absolute"></div>
              <div className="poppins-bold text-[12px] font-bold w-full text-center">NUMBER OF VIEWS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[24px] leading-tight">
              {noOfViews}
            </div>  
          </div>

          <div className="w-full h-auto flex flex-col justify-start items-start bg-white rounded-[10px] p-4 mt-5 flex-grow overflow-hidden">
            <div className="flex justify-start items-center h-fit w-full relative">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full absolute"></div>
              <div className="poppins-bold text-[12px] font-bold w-full text-center capitalize">Categories</div>
            </div>
            
            <div className='scrollable-section w-full flex-grow overflow-y-auto mt-3 hidescrl'>
              {c1Name && 
                <div className='flex flex-col'>
                  <div className='poppins-bold text-[12px] font-bold pb-2'>{capitalizeFirstLetter(c1Name)}</div>
                  <div className='flex flex-wrap w-full h-auto gap-2 text-[10px] leading-tight'>
                    {c1?.map((item: any, idx) => 
                      <div key={idx} className='bg-white border border-black rounded-full px-2'>{item}</div>  
                    )}
                  </div>
                </div>
              }

              {c2Name &&
                <div className='flex flex-col mt-3'>
                  <div className='poppins-bold text-[12px] font-bold pb-2'>{capitalizeFirstLetter(c2Name)}</div>
                  <div className='flex flex-wrap w-full h-auto gap-2 text-[10px] leading-tight'>
                    {c2?.map((item: any, idx) => 
                      <div key={idx} className='bg-white border border-black rounded-full px-2'>{item}</div>  
                    )}
                  </div>
                </div>
              }

              {c3Name &&
              <div className='flex flex-col mt-3'>
                <div className='poppins-bold text-[12px] font-bold pb-2'>{capitalizeFirstLetter(c3Name)}</div>
                <div className='flex flex-wrap w-full h-auto gap-2 text-[10px] leading-tight'>
                  {c3?.map((item: any, idx) => 
                    <div key={idx} className='bg-white border border-black rounded-full px-2'>{item}</div>  
                  )}
                </div>
              </div>}

              {c4Name &&
              <div className='flex flex-col mt-3'>
                <div className='poppins-bold text-[12px] font-bold pb-2'>{capitalizeFirstLetter(c4Name)}</div>
                <div className='flex flex-wrap w-full h-auto gap-2 text-[10px] leading-tight'>
                  {c4?.map((item: any, idx) => 
                    <div key={idx} className='bg-white border border-black rounded-full px-2'>{item}</div>  
                  )}
                </div>
              </div>}
            </div>
            
          </div>
        </div>

        <div className='flex justify-center items-center py-2 h-[80px] lg:h-[28px] w-full gap-x-5'>
          <i className='ri-edit-box-line text-[32px] text-black leading-none' onClick={(e)=>{
            e.stopPropagation();
            onEditClick && onEditClick()
          }}/>
          <i className='ri-delete-bin-5-line text-[32px] text-black leading-none' onClick={(e)=>{
            e.stopPropagation();
            onDeleteClick && onDeleteClick()
          }}/>
        </div>
    </div>
  )
}

export default AppDetailsCard