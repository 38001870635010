import React from 'react';
import classNames from 'classnames';

interface ProgressBarProps {
  total: number ;
  count: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ total, count }) => {
  const fillPercentage = Math.min((count / total) * 100, 100);

  return (
    <div className="w-full bg-[#373737] rounded-full h-3">
      <div
        className={classNames('h-3 rounded-full', {
          'bg-[#A4A4A4]': fillPercentage > 0,
        })}
        style={{ width: `${fillPercentage}%` }}
      />
    </div>
  );
};

export default ProgressBar;