/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, ReactNode } from 'react'
import CN from 'classnames'
import { motion } from 'framer-motion'

import { Button } from '../Button'

interface ModalProps {
  [x: string]: any
  children?: ReactNode | string | number | undefined
  className?: string | undefined
  customWidth?: string | undefined
  customZIndex?: number | undefined
  footerButtonAlignment?: 'left' | 'right' | 'center' | 'between'
  headerTitle?: string
  isActive?: boolean | undefined
  isCustomStyles?: boolean
  isFooterShow?: boolean
  isHeaderShow?: boolean
  isSeparator?: boolean
  onClickPrimaryBtn?: () => void | undefined
  onClickSecondaryBtn?: () => void | undefined
  onHeaderCloseButtonClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  primaryButtonTitle?: string
  secondaryButtonTitle?: string
  size?: 'sm' | 'md' | 'lg'
  modalContainerClassName?: string | undefined
  headerTitleClassName?: string | undefined
}

export const Modal: FC<ModalProps> = ({
  children,
  className,
  customWidth,
  customZIndex,
  footerButtonAlignment,
  headerTitle,
  isActive,
  isCustomStyles,
  isFooterShow,
  isHeaderShow,
  isSeparator,
  onClickPrimaryBtn,
  onClickSecondaryBtn,
  onHeaderCloseButtonClick,
  onOverlayClick,
  primaryButtonTitle,
  secondaryButtonTitle,
  size,
  modalContainerClassName,
  headerTitleClassName,
  ...restProps
}: ModalProps) => {
  const OverlayClassName = !isCustomStyles
    ? CN(
        'modal-overlay fixed flex top-0 bottom-0 left-0 right-0 justify-center items-center h-full bg-N-800 bg-opacity-70',
        className,
        {
          hidden: !isActive,
        },
        {
          'z-50': customZIndex === undefined,
          'z-10': customZIndex === 10,
          'z-20': customZIndex === 20,
          'z-30': customZIndex === 30,
          'z-40': customZIndex === 40,
          'z-60': customZIndex === 60,
          'z-70': customZIndex === 70,
          'z-80': customZIndex === 80,
          'z-90': customZIndex === 90,
          'z-100': customZIndex === 100,
        },
      )
    : className

  /** main modal container styles */
  const ModalContainerClassName = CN(
    'modal-container flex flex-col justify-between items-center h-auto bg-white shadow-lg rounded-sm',
    customWidth === undefined && {
      'w-[400px]': size === 'sm',
      'w-[600px]': size === 'md',
      'w-[800px]': size === 'lg',
    },modalContainerClassName
  )

  /** main modal header styles */
  const ModalHeaderClassName = CN(
    'modal-Header flex w-full justify-between pl-6 py-[17px] pr-6',
    {
      'border-b-[1px] border-[#E5E7EB]': isSeparator,
    },
  )

  /** prevent modal hide when click inside modal */
  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  return (
    <div
      className={OverlayClassName}
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
        onOverlayClick && onOverlayClick()
      }}
      {...restProps.overlayProps}>
      {/* Modal main container  */}
      <motion.div
        animate={isActive ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, y: 0, transition: { duration: 0.1 } },
          closed: { opacity: 1, y: '-100%' },
        }}
        className={ModalContainerClassName}
        style={customWidth && { width: customWidth }}
        {...restProps.modalProps}
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}

        {isHeaderShow && (
          <div className={ModalHeaderClassName} {...restProps.headerProps}>
            <span className={CN('header-title flex justify-center items-center text-heading-5 font-medium text-N-800',headerTitleClassName)}>
              {headerTitle}
            </span>

            <i
              className='ri-close-fill w-3 flex justify-center items-center cursor-pointer'
              {...restProps.headerCloseButtonProps}
              onClick={(e: any) => {
                e.stopPropagation()
                e.preventDefault()
                onHeaderCloseButtonClick && onHeaderCloseButtonClick()
              }}></i>
          </div>
        )}

        {/* Modal body */}
        {children}

        {/* Modal footer */}
        {isFooterShow && (
          <div
            className={CN(
              'modal-footer flex w-full py-3 pl-6 px-4 bg-N-50 items-center border-t border-t-N-200',
              {
                'justify-start': footerButtonAlignment === 'left',
                'justify-end': footerButtonAlignment === 'right',
                'justify-center': footerButtonAlignment === 'center',
                'justify-between': footerButtonAlignment === 'between',
              },
            )}
            {...restProps.footerProps}>
            {/*footer primary button*/}
            {primaryButtonTitle !== '' && (
              <Button
                className='mr-2' 
                appearance='default'
                onClick={() => {
                  onClickPrimaryBtn && onClickPrimaryBtn()
                }}
                {...restProps.primaryButtonProps}>
                {primaryButtonTitle}
              </Button>
            )}

            {/*footer secondary button*/}
            {secondaryButtonTitle !== '' && (
              <Button
                appearance='outline'
                onClick={() => {
                  onClickSecondaryBtn && onClickSecondaryBtn()
                }}
                {...restProps.secondaryButtonProps}>
                {secondaryButtonTitle}
              </Button>
            )}
          </div>
        )}
      </motion.div>
    </div>
  )
}

export default Modal

Modal.defaultProps = {
  children: undefined,
  className: undefined,
  customWidth: undefined,
  customZIndex: undefined,
  footerButtonAlignment: 'left',
  headerTitle: 'Lorem ipsum',
  isActive: true,
  isCustomStyles: false,
  isFooterShow: true,
  isHeaderShow: true,
  isSeparator: true,
  onClickPrimaryBtn: undefined,
  onClickSecondaryBtn: undefined,
  onHeaderCloseButtonClick: undefined,
  onOverlayClick: undefined,
  primaryButtonTitle: 'Lorem',
  secondaryButtonTitle: 'Lorem',
  size: 'sm',
  modalContainerClassName:undefined,
  headerTitleClassName:undefined,
}
