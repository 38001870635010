import { FC } from 'react'
import CN from 'classnames'

export interface BadgeProps {
  className?: string | undefined
  appearance?: 'success' | 'warning' | 'error' | undefined
  onClick?: (e: any) => void | undefined
  labelValue?: string | undefined
  [x: string]: any
}

export const Badge: FC<BadgeProps> = ({
  className,
  appearance,
  labelValue,
  onClick,
}: BadgeProps) => {
  const badgeClassnames = CN(
    'flex items-center justify-center w-fit rounded-sm text-white',
    {
      'bg-G-50 text-G-700': appearance === 'success',
      'bg-R-50 text-R-700': appearance === 'error',
      'bg-Y-50 text-Y-700': appearance === 'warning',
    },
    {
      'curser-pointer': onClick,
    },
    className,
  )
  return (
    <div className={badgeClassnames}>
      <p className='flex items-center justify-center text-xs font-semibold px-2 pb-[1px]'>
        {labelValue}
      </p>
    </div>
  )
}

Badge.defaultProps = {
  className: undefined,
  appearance: 'success',
  labelValue: undefined,
  onClick: undefined,
}

export default Badge
