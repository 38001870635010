import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetAppByIdInterface {
  appId: string
}

export const useGetAppById = () => {
  const mutate = useMutation(({appId}: GetAppByIdInterface) => {
    return get(`/external-api/get_app-by_ID/${appId}`)
  })

  return mutate
}

export default useGetAppById
