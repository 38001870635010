/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useState } from 'react'
import CN from 'classnames'

export interface PopOutProps {
  className?: string | undefined
  isCustomStyles?: boolean | undefined
  appearance?: 'success' | 'warning' | 'error' | undefined
  isShowCloseButton?: boolean | undefined
  size?: 'small' | 'default' | undefined
  onClose?: () => void | undefined
  description?: string | undefined
  isOrderPopOut?: boolean | undefined
  isSelfCloseByClick?: boolean | undefined
  [x: string]: any
}

export const PopOut: FC<PopOutProps> = ({
  children,
  className,
  isCustomStyles,
  appearance,
  isShowCloseButton,
  description,
  size,
  isOrderPopOut,
  isSelfCloseByClick,
  onClose,
}: PopOutProps) => {
  const [isActive, setIsActive] = useState(true)
  const PopOutClasses = !isCustomStyles
    ? CN(
        'PopOut rounded flex items-center justify-between',
        className,
        {
          'PopOut bg-G-100': appearance === 'success',
          'PopOut bg-Y-100': appearance === 'warning',
          'PopOut bg-R-100': appearance === 'error',
        },
        {
          hidden: !isActive,
          block: isActive,
        },
        {
          'p-3': size === 'small',
          'p-4': size === 'default',
        },
      )
    : className

  const iconBeforeClasses = CN(
    'pr-[14px] text-[20px]',
    {
      'ri-checkbox-circle-fill text-G-500': appearance === 'success',
      'ri-error-warning-fill text-Y-500': appearance === 'warning',
      'ri-error-warning-fill text-R-500': appearance === 'error',
    },
    {
      hidden: !isActive,
      block: isActive,
    },
  )

  const headerClasses = CN('text-N-700', {
    'font-bold': isOrderPopOut,
  })

  return (
    <div className={PopOutClasses}>
      <div className='flex items-center'>
        <i className={iconBeforeClasses}></i>
        <div className='flex flex-col items-start justify-center'>
          <p className={headerClasses}>{children}</p>
          {description && (
            <p className='text-md font-normal text-N-700'>{description}</p>
          )}
        </div>
      </div>
      {isShowCloseButton && (
        <i onClick={onClose} className='ri-close-line cursor-pointer'></i>
      )}
      {isSelfCloseByClick && (
        <i
          onClick={() => {
            setIsActive(false)
          }}
          className='ri-close-line cursor-pointer'></i>
      )}
    </div>
  )
}

PopOut.defaultProps = {
  className: undefined,
  appearance: undefined,
  isShowCloseButton: false,
  onClose: undefined,
  description: undefined,
  isOrderPopOut: false,
  isSelfCloseByClick: false,
  size: 'default',
}

export default PopOut
