import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetDownloadsListInterface {
  appId: string
  page: number
  limit: number
}

export const useGetDownloadListByAppId = () => {
  const mutate = useMutation(({appId, page, limit}: GetDownloadsListInterface) => {
    return get(`/external-api/get_downloads_details/${appId}?page=${page}&limit=${limit}`)
  })

  return mutate
}

export default useGetDownloadListByAppId
