import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface GetAppByIdInterface {
  appId: string
  category_1: string
  category_2: string
  category_3: string
  category_4: string
}

export const useGetAllTemplates = () => {
  const mutate = useMutation(({
    appId,
    category_1,
    category_2,
    category_3,
    category_4}: GetAppByIdInterface) => {
    return postAPI(`/external-api/getAlltemplates/${appId}`,{
      category_1,
      category_2,
      category_3,
      category_4
    })
  })

  return mutate
}

export default useGetAllTemplates
