import { HeaderSection } from "components/molecules"
import { useNavigate, useParams } from "react-router-dom"
import AI from 'assets/images/AI.png'
import { PageLoader, ProgressBar, Rating } from "components/atoms"
import { toast } from "react-toastify"
import { useGetAppById } from "framework/api/methods"
import { useEffect, useState } from "react"

export const AppDetails = () => {
  const navigate =useNavigate()
  const params = useParams()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  
  const [selectedAppData, setSelectedAppData] = useState<any>(null)
  


  const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()


 // get app details by id
 const getAppById = () => {
  getAppByIdMutate({
    appId: params.id?.toString() || ''
   }, {
     onSuccess: ({ data: successData }: any) => {
    
      const data = successData?.data[0]
      const urls = data.cover_images.slice(1, -1).split(", ");

      const urlArray = urls.map((url: any) => url.slice(1, -1));

      setSelectedAppData({
        app_id: data.app_id,
        app_icon: data.app_icon,
        app_name: data.app_name,
        app_description: data.app_description,
        views_count: data.views_count,
        average_rating: data.average_rating,
        rating_count: data.rating_count,
        vender_name: data.vender_name,
        short_description: data.short_description,
        cover_images: urlArray,
        total_ratings: data?.total_ratings,
        count_1_star: data?.count_1_star,
        count_2_star: data?.count_2_star,
        count_3_star: data?.count_3_star,
        count_4_star: data?.count_4_star,
        count_5_star: data?.count_5_star,
        category_1: data?.category_1,
        app_type: data?.app_type,
        status: data?.status,
      })

     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        notify('Session expired.', 'error')
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
}

   
 useEffect(() => {
  if(params.id){
    getAppById()
  }
 }, [params.id])

  return (
    <div className='w-full flex flex-col h-full'>
      <HeaderSection title="APPS DETAILS"  onClickBackButton={()=>{
        navigate('/apps-manage')
      }}  />
      {getAppByIdIsLoading && <div className="w-full flex justify-center items-center"><PageLoader size="md" className="w-full"/></div>}
      {selectedAppData && 
      <div className="flex w-full h-full p-5 overflow-y-auto hidescrl">
        <div className="w-full h-full flex bg-white rounded-[30px] justify-start items-start md:justify-start md:items-start overflow-y-auto hidescrl">
          <div className="w-full md:w-full h-auto flex flex-col px-10 py-2">
            <div className="flex gap-x-3 w-full justify-between">
              <div className="flex flex-col">
                <div className="headings-font text-[32px] leading-tight font-medium">{selectedAppData?.app_name}</div>
                <div className="captions-font text-sm pt-1 w-full">{selectedAppData?.vender_name}</div>

                <div className="captions-font text-sm pt-4 w-full">{selectedAppData?.short_description}</div>
              </div>

              {selectedAppData.app_icon &&
              <img src={selectedAppData.app_icon} 
                alt={'app-icon'} 
                className="w-[60px] h-[60px] md:w-[90px] md:h-[90px] rounded-full"
              />}
            </div>

            <div className="flex gap-x-2">
              <div className="flex justify-center items-center captions-font  text-sm">{selectedAppData?.average_rating.toFixed(1)} <i className="ri-star-fill pl-1" /></div>
              <div className="flex justify-center items-center captions-font  text-sm">{selectedAppData?.views_count} <i className="ri-eye-line pl-1" /></div>
            </div>

            <div className="captions-font text-sm pt-4 w-full">{selectedAppData?.app_description}</div>
            <div className="captions-font text-sm pt-4 w-full">Status : {selectedAppData?.status==="true" ? 'Active' : 'Inactive'}</div>

            <div className="flex flex-col md:flex-row h-full mt-4 md:gap-x-5 gap-y-2">
              <div className="flex flex-col h-full w-full md:w-1/2 border-t border-t-neutral-300">
                <span className="w-full text-left pt-5 captions-font text-[10px] leading-none">What Users think</span>

                <div className="flex gap-x-5 mt-3">
                  <div className="flex flex-col gap-y-2">
                    <div className="text-[50px] leading-tight">{selectedAppData?.average_rating.toFixed(1)}</div>
                    <Rating selectedRating={selectedAppData?.user_rating} totalStars={5} className="w-[100%]" iconClassName='text-[10px] leading-none' defaultValue={selectedAppData?.average_rating} isEditable={false}/>
                    <div className="captions- text-xs">{selectedAppData?.rating_count}</div>
                  </div>

                  <div className="flex flex-col w-full gap-y-2 captions-font">
                    <div className="flex gap-x-2 justify-center items-center">
                      <span className="text-xs w-[20px]">5</span>
                      <ProgressBar count={selectedAppData?.count_5_star} total={selectedAppData.rating_count} />
                    </div>

                    <div className="flex gap-x-2 justify-center items-center">
                      <span className="text-xs w-[20px]">4</span>
                      <ProgressBar count={selectedAppData?.count_4_star} total={selectedAppData.rating_count} />
                    </div>

                    <div className="flex gap-x-2 justify-center items-center">
                      <span className="text-xs w-[20px]">3</span>
                      <ProgressBar count={selectedAppData?.count_3_star} total={selectedAppData.rating_count} />
                    </div>

                    <div className="flex gap-x-2 justify-center items-center">
                      <span className="text-xs w-[20px]">2</span>
                      <ProgressBar count={selectedAppData?.count_2_star} total={selectedAppData.rating_count} />
                    </div>

                    <div className="flex gap-x-2 justify-center items-center">
                      <span className="text-xs w-[20px]">1</span>
                      <ProgressBar count={selectedAppData?.count_1_star} total={selectedAppData.rating_count} />
                    </div>
                  </div>

                </div>
              </div>

              <div className="flex h-full w-full md:w-1/2 gap-x-2">
                {selectedAppData?.cover_images.map((image: any, index: number) =>
                    <img src={image ? image : undefined}
                      className="w-[calc(33.3%-6px)] h-[300px] object-cover object-center"
                      alt={`cover-images-${index}`} 
                      key={index}
                    />
                )}    
              </div>
            </div>
          </div>

        </div>
      </div>
      }
    </div>
  )
}

export default AppDetails