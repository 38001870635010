const formatDate = (timestamp: string)=> {
    const date = new Date(timestamp);
    
    // Get year, month, day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Format the date and time as "YYYY-MM-DD HH:MM"
    return `${year}-${month}-${day}`;
}

export default formatDate;