import { ColumnDef } from "@tanstack/react-table"
import { SimpleSelect } from "components/atoms"
import { HeaderSection, UITable } from "components/molecules"
import { useGetAllApps, useGetQuotationsListByAppId } from "framework/api/methods"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import formatDateTime from "utils/formatDateTime"

export const Quotation = () => {
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'q_ID',
        header: 'Quotation ID',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'user_phone',
        header: 'Contact number',
        headerClassName: '!min-w-[220px]',
        cellClassName:'!min-w-[220px]',
        cell: info => info.getValue(),
      },  
      {
        accessorKey: 'imageURL',
        header: 'Image',
        headerClassName: '!min-w-[150px] !max-w-[200px] !px-0',
        cellClassName:'!min-w-[150px] !max-w-[200px] !px-0',
        cell:   props => {
          const url: any = props.getValue();
          return <div className="line-clamp-1 underline text-[#283593] p-1"><a href={url} target="_blank">
             <img src={url} 
                alt={'img-url'} 
                className="w-[50px] h-[50px]"
              />
              </a></div>; // Display first letter or empty if no value
        },
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        headerClassName: '!min-w-[150px]',
        cellClassName:'!min-w-[150px]',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'required_date',
        header: 'Required date',
        headerClassName: '!min-w-[250px]',
        cellClassName:'!min-w-[250px]',
        cell:   props => {
          const date: any = props.getValue();
          return <div>{date && formatDateTime(date.toString())}</div>; // Display first letter or empty if no value
        },
      },
      {
        accessorKey: 'instructions',
        header: 'Instructions',
        headerClassName: '!min-w-[250px]',
        cellClassName:'!min-w-[250px]',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'created_at',
        header: 'created_at',
        headerClassName: '!min-w-[250px]',
        cellClassName:'!min-w-[250px]',
        cell:   props => {
          const date: any = props.getValue();
          return <div>{date && formatDateTime(date.toString())}</div>; // Display first letter or empty if no value
        },
      },
    ],
    []
  )

  const navigate = useNavigate()
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [appList, setAppList] = useState([])
  const [selectedApp, setSelectedApp] = useState<any>(null)
  const [paginationProps, setPaginationProps] = useState<any>({
    limit: 10,
    page: 1,
  })
  const [pageCount, setPageCount] = useState(0)
  const [tableData, setTableData] = useState([])
  
 
  

  const onSuccess =(data: any)=>{
    setAppList(data.map((item: any) => ({
      label: item.app_name,
      value: item.app_id
    })))
  }

  const onError =({ response: { data: errData }, response }: any)=>{
    if(response?.status===405 || response?.status===403 || response?.status===501 ){
      notify('Session expired.', 'error')
      setTimeout(()=>{
        navigate('/auth/login')
        localStorage.clear()
      }, 2000);
    }else{
      notify(errData?.message || 'Something went wrong.', 'error')
    }
  }

  const { refetch:getAppList , isLoading: getAppListIsLoading } = useGetAllApps(onSuccess,onError)

  useEffect(() => {
    if(appList.length>0){
      setSelectedApp(appList[0])
    }
  }, [appList])

  const { mutate: getQuotationsListByAppIdMutate, isLoading: getQuotationsListByAppIdIsLoading, } = useGetQuotationsListByAppId()
  
  // get app Quotation by id
  const getQuotationsListByAppId = (appId: string) => {
    getQuotationsListByAppIdMutate({
      appId,
      limit: paginationProps?.limit,
      page:  paginationProps?.page
    }, {
      onSuccess: ({ data: successData }: any) => {
        setTableData(successData?.data || [])
        setPageCount(successData.totalPages)
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }


  useEffect(() => {
   if(selectedApp){
    getQuotationsListByAppId(selectedApp.value)
   }
  }, [selectedApp, paginationProps])
  

  return (
    <div className='w-full flex flex-col h-full relative'>
      <HeaderSection title="QUOTATIONS" />

      <div className="flex w-full h-full p-5 overflow-y-auto hidescrl">
        <div className="w-full h-full flex flex-col bg-white rounded-[30px] justify-start items-start overflow-y-auto hidescrl py-4 px-2 md:px-6">
         <div className="flex flex-col md:flex-row w-full justify-start items-center  gap-x-2">
            <div className="poppins-medium w-[95px]">Select App:</div>
            
            <SimpleSelect 
              placeholder="Select..."
              value={selectedApp}  
              className="w-full md:w-[300px]"
              onChange={(e)=>{
                setSelectedApp(e)
                setPaginationProps({
                  limit: 10,
                  page: 1,
                })
              }}
              options={appList} 
            />
          </div>

          <div className="w-full h-full">
            <UITable 
              columns={columns}
              data={tableData}
              paginationProps={paginationProps}
              setPaginationProps={setPaginationProps}
              pageCount={pageCount}
              isLoading={getQuotationsListByAppIdIsLoading || getAppListIsLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Quotation