/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CN from 'classnames'
import { setBreadcrumb } from 'store/reducers/header/headerSlice'

export interface LeftNavProps {
  [x: string]: any
  menuItemList?: any
  firstRoute?: string | undefined
  secondRoute?: string | undefined
  setIsMobileMenuOpen?: any | undefined
}

export const LeftNav: FC<LeftNavProps> = ({
  menuItemList,
  firstRoute,
  secondRoute,
  userDetails,
  setIsMobileMenuOpen,
}: LeftNavProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [menuItems, setMenuItems] = useState<any>([])
  const [firstBreadcrumb, setFirstBreadcrumb] = useState('')
  const [secondBreadcrumb, setSecondBreadcrumb] = useState('')

  useEffect(() => {
    const filterSubMenus = menuItemList.map((item: any) => {
      return {
        ...item,
        subMenuItems: item.subMenuItems?.filter((item: any) => {
          return item.isActive
        }),
      }
    })

    const filterMainMenu = filterSubMenus?.filter((item: any) => {
      return item.isActive
    })

    setMenuItems(filterMainMenu)
  }, [userDetails])

  useEffect(() => {
    dispatch(
      setBreadcrumb(secondBreadcrumb ? secondBreadcrumb : firstBreadcrumb),
    )
  }, [firstBreadcrumb, secondBreadcrumb])

  useEffect(() => {
    if (menuItems.length > 0) {
      setSecondBreadcrumb('')
      setFirstBreadcrumb('')
      const itemsToUpdate = menuItems

      if (firstRoute || firstRoute === '') {
        const selectedIndex = itemsToUpdate.findIndex((item: any) => {
          return item.url === `/${firstRoute}`
        })
        itemsToUpdate[selectedIndex].isSelected = true
        setFirstBreadcrumb(itemsToUpdate[selectedIndex]?.name)
        setMenuItems(itemsToUpdate.slice())
      }
    }
  }, [firstRoute,userDetails])

  const clickMainItem = (selectedItem: any) => {
    const itemToUpdate = menuItems
    if (!selectedItem.isSelected) {
      !selectedItem.hasSubmenu && navigate(selectedItem.url)
      const updatedItems = itemToUpdate.map((item: any) => {
        if (item.mainMenuId === selectedItem.mainMenuId) {
          return {
            ...item,
            isSelected: true,
            subMenuItems: item.subMenuItems.map((subItem: any) => {
              return {
                ...subItem,
                isSelected: false,
              }
            }),
          }
        } else {
          return {
            ...item,
            isSelected: false,
            subMenuItems: item.subMenuItems.map((subItem: any) => {
              return {
                ...subItem,
                isSelected: false,
              }
            }),
          }
        }
      })
      setMenuItems(updatedItems)
    }
  }

  return (
    <div className='side-bar-item-container h-full w-full bg-[#252529]'>
      <div className='lg:flex hidden poppins-regular mt-[20px] md:mt-8 lg:text-[32px] md:text-2xl text-xl text-white leading-tight w-full text-center pb-10'>AI Marketplace</div>

      <ul className='flex flex-col h-full gap-y-1 pt-10 lg:pt-0'>
        {menuItems?.map((item: any, idx: any) => (
          <li key={item?.mainMenuItemKey} className='side-bar-item h-fit'>
            <div>
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  clickMainItem(item)
                  setIsMobileMenuOpen && setIsMobileMenuOpen(false)
                  sessionStorage.clear()
                }}
                className={CN(
                  'side-bar-item__container flex items-center justify-between h-full w-full py-3 md:py-2 lg:py-1  rounded-md transition-all duration-500 px-5 cursor-pointer',
                  {
                    'text-white': item?.isSelected,
                    'hover:text-white text-[#929294]': !item?.isSelected,
                  },
                )}>
                <div className='flex items-center justify-start w-full'>
                  <span className='lg:text-[36px] md:text-[30px] text-[24px] leading-none pr-2'>{item?.icon}</span>
                  <p className='font-medium lg:text-lg md:text-sm sm:text-xs'>{item?.name}</p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

LeftNav.defaultProps = {
  initialObj: undefined,
  isFullMenu: true,
  firstRoute: undefined,
  secondRoute: undefined,
}

export default LeftNav
