/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import CN from 'classnames'

import { SideBar } from 'components/common/SideBar'
import { setUserDetails } from 'store/reducers/user/userSlice'
import { Drawer, LeftNav } from 'components/atoms'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export interface MainLayoutProps {
  hasSubHeading?: boolean
  [x: string]: any
}

export const MainLayout: FC<MainLayoutProps> = ({
  children,
}: MainLayoutProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)
  const { isLogOutModalOpen }: any = useSelector<any>((state) => state.user)
  const [isActiveLogoutModal, setIsActiveLogoutModal] =
    useState(isLogOutModalOpen)
  const urlLocation = useLocation()
  const dispatch = useDispatch()
  const firstRoute = urlLocation.pathname.split('/')[1]
  const secondRoute = urlLocation.pathname.split('/')[2]
  const token = localStorage.getItem('token')
  /** API Call for Login function using react-query */

  useEffect(() => {
    setIsActiveLogoutModal(isLogOutModalOpen)
    dispatch(setUserDetails({firstName:'Super',lastName:'Admin'}))
  }, [token, isLogOutModalOpen])

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)


  const initialObj = [
    {
      mainMenuId: 1,
      mainMenuItemKey: Math.random(),
      name: 'Dashboard',
      url: '/',
      icon: <i className='ri-dashboard-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive: true,
      subMenuItems: [],
    },
    {
      mainMenuId: 2,
      mainMenuItemKey: Math.random(),
      name: 'Apps Manage',
      url: '/apps-manage',
      icon: <i className='ri-function-add-line rotate-45'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive: true,
      subMenuItems: [],
    },
    {
      mainMenuId: 3,
      mainMenuItemKey: Math.random(),
      name: 'Template Manage',
      url: '/template-manage',
      icon: <i className='ri-file-add-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive: true,
      subMenuItems: [],
    },
    {
      mainMenuId: 4,
      mainMenuItemKey: Math.random(),
      name: 'Ratings',
      url: '/ratings',
      icon: <i className='ri-star-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive: true,
      subMenuItems: [],
    },
    {
      mainMenuId: 5,
      mainMenuItemKey: Math.random(),
      name: 'Wallet',
      url: '/wallet',
      icon: <i className='ri-wallet-2-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive: true,
      subMenuItems: [],
    },
    {
      mainMenuId: 6,
      mainMenuItemKey: Math.random(),
      name: 'Users',
      url: '/users',
      icon: <i className='ri-group-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive: true,
      subMenuItems: [],
    },
    {
      mainMenuId: 7,
      mainMenuItemKey: Math.random(),
      name: 'Quotations',
      url: '/quotations',
      icon: <i className='ri-article-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive: true,
      subMenuItems: [],
    },
    {
      mainMenuId: 8,
      mainMenuItemKey: Math.random(),
      name: 'Downloads',
      url: '/downloads',
      icon: <i className='ri-download-2-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive: true,
      subMenuItems: [],
    },
  ]

  return (
    <>
      <div className='w-full h-screen'>
        <div className='bg-[#252529] h-[40px] flex lg:hidden justify-between items-center px-2'>
          <i className='ri-menu-line leading-[32px] text-[24px] text-white' onClick={()=>{setIsMobileMenuOpen(true)}}/>
          <div className='text-white'>AI Marketplace</div>
        </div>
       
        <SideBar
          isFullMenu={true}
          firstRoute={firstRoute}
          secondRoute={secondRoute}
          menuItemList={initialObj}
          userDetails={userDetails}
        />

        <div
          className={CN(
            'main-layout__content z-10 fixed top-[40px] lg:top-0 h-[calc(100%-40px)] lg:h-full lg:left-[20%] w-full lg:w-[80%] bg-[#D1D1D1] ',
          )}>
          {children}
        </div>

        <Drawer
        isActive={isMobileMenuOpen}
        isFooterShow={false}
        width='250px'
        slideRight={true}
        onHeaderCloseButtonClick={()=>{
          setIsMobileMenuOpen(false)
        }}
        onOverlayClick={()=>{
          setIsMobileMenuOpen(false)
        }}
        isHeaderShow={false}
        >
        <div className='flex flex-col items-center w-full h-full px-[10px] py-2 bg-[#252529] overflow-hidden'>
          <LeftNav
            menuItemList={initialObj}
            firstRoute={firstRoute || ''}
            secondRoute={secondRoute}
            userDetails={userDetails}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        </div>
      </Drawer>

      <ToastContainer
            position='bottom-center'
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
      </div>
    </>
  )
}

MainLayout.defaultProps = {
  hasSubHeading: true,
}

export default MainLayout
