import { FC } from 'react'
import CN from 'classnames'
import { Loader } from '../Loader'

export interface PageLoaderProps {
  className?: string | undefined
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | undefined
}

export const PageLoader: FC<PageLoaderProps> = ({ size }: PageLoaderProps) => {
  
  return (
    <div
      className={CN('opacity-90', {
        'w-full': size === 'lg',
        'w-1/2': size === 'md',
        'w-1/4': size === 'sm',
        'w-1/6': size === 'xs',
        'w-1/12': size === 'xxs',
      })}>
      <Loader className='mt-10' appearance='black'/>
    </div>
  )
}

PageLoader.defaultProps = {
  className: undefined,
  size: 'xs',
}

export default PageLoader
