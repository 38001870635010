import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetQuotationsListInterface {
  appId: string
  page: number
  limit: number
}

export const useGetQuotationsListByAppId = () => {
  const mutate = useMutation(({appId, page, limit}: GetQuotationsListInterface) => {
    return get(`/external-api/getAll_quotation_app/${appId}?page=${page}&limit=${limit}`)
  })

  return mutate
}

export default useGetQuotationsListByAppId
