import { FC } from 'react'
import CN from 'classnames'
import { Button } from 'components/atoms'

export interface HeaderSectionProps {
  className?: string | undefined
  title: string
  onClickBackButton?: (e: any) => void | undefined
  [x: string]: any
}

export const HeaderSection: FC<HeaderSectionProps> = ({
  className,
  title,
  onClickBackButton,
}: HeaderSectionProps) => {
  const headerClassnames = CN(
    'flex items-center justify-center w-full bg-white poppins-medium text-[#1D1D1F] relative px-5 lg:py-[14px] py-[16px]',
    className,
  )
  return (
    <div className={headerClassnames}>
      {/* mobile btn */}
      {onClickBackButton &&
      <Button
        appearance='default'
        className='absolute left-2 lg:hidden flex'
        size='xs'
        onClick={onClickBackButton}
      >
       Back
      </Button>}

      {/* desktop btn */}
      {onClickBackButton &&
      <Button
        appearance='default'
        className='absolute left-10 hidden lg:flex'
        size='md'
        onClick={onClickBackButton}
      >
        Back
      </Button>
      }
      <div className='lg:text-2xl md:text-xl text-l'>{title}</div>
    </div>
  )
}

HeaderSection.defaultProps = {
  className: undefined,
  onClickBackButton: undefined,
}

export default HeaderSection
