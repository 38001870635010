import { useQuery } from "react-query";
import { get } from "framework/api/http";

/* Get all cards */
export const getAllApps = async () => {
    const res = await get(`/external-api/get_all_apps`)
  
    return res?.data?.data || []
  }

/* Use Query as a Hook */
export const useGetAllApps = (onSuccess: any, onError: any) => {
    return useQuery('app-list', getAllApps, {
      refetchOnWindowFocus: false,
      enabled: true,
      retry:2,
      onSuccess,
      onError,
    })
  }

export default useGetAllApps;


