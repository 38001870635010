import { HeaderSection } from "components/molecules"
import { useNavigate, useParams } from "react-router-dom"
import { Button, InputTextArea, Loader, PageLoader, SimpleSelect, ToggleSwitch} from "components/atoms"
import { toast } from "react-toastify"
import { useCallback, useEffect, useState } from "react"
import { handleUploadImage, useAddNewTemplate, useGetAppById, useGetTemplateById, useUpdateApp, useUpdateTemplate } from "framework/api/methods"
import capitalizeFirstLetter from "utils/capitalizeFirstLetter"
import { useDropzone } from "react-dropzone"

export const UpdateTemplate = () => {
  const navigate =useNavigate()
  const params = useParams()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }


  const category_1_name = sessionStorage.getItem('category_1_name')
  const category_2_name = sessionStorage.getItem('category_2_name')
  const category_3_name = sessionStorage.getItem('category_3_name')
  const category_4_name = sessionStorage.getItem('category_4_name')
  const app_name = sessionStorage.getItem('temp-app-name')


  const [vendorName, setVendorName] = useState('')
  const [category1, setCategory1] = useState<any>({
    name: null,
    list: []
  })
  const [category2, setCategory2] = useState<any>({
    name: null,
    list: []
  })
  const [category3, setCategory3] = useState<any>({
    name: null,
    list: []
  })
  const [category4, setCategory4] = useState<any>({
    name: null,
    list: []
  })

  const [templateImageUrl, setTemplateImageUrl] = useState('')
  const [coverImageUrl, setCoverImageUrl] = useState('')
  const [coverImageLoding, setCoverImageLoding] = useState(false)
  const [templateImageLoding, setTemplateImageLoding] = useState(false)

  const [formData, setFormData] = useState<any>({
    category1 : null,
    category2: null,
    category3: null,
    category4: null,
    status: true,
    longDescription: '',
  })

  const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()
  const { mutate: updateTemplateMutate, isLoading: updateTemplateIsLoading, } = useUpdateTemplate()

  

  // get app details by id
  const getAppById = (appId: string) => {
    getAppByIdMutate({
      appId
    }, {
      onSuccess: ({ data: successData }: any) => {
      
        const data = successData?.data[0]
        setVendorName(data?.vender_name)
        setCategory1({
          name: JSON.parse(data.category_1)[0]?.name,
          list: JSON.parse(data.category_1)[0]?.data?.map((item: any) => ({
            label: item,
            value: item
          }))
        })

        setCategory2({
          name: JSON.parse(data.category_2)[0]?.name,
          list: JSON.parse(data.category_2)[0]?.data?.map((item: any) => ({
            label: item,
            value: item
          }))
        })


        setCategory3({
          name: JSON.parse(data.category_3)[0]?.name,
          list: JSON.parse(data.category_3)[0]?.data?.map((item: any) => ({
            label: item,
            value: item
          }))
        })

        setCategory4({
          name: JSON.parse(data.category_4)[0]?.name,
          list: JSON.parse(data.category_4)[0]?.data?.map((item: any) => ({
            label: item,
            value: item
          }))
        })
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }


  

  // add nwe template
  const updateTemplate = () => {
    updateTemplateMutate({
      templateID: params?.templateId || '',
      appID: params?.appId || '',
      coverImage: coverImageUrl,
      template_imageURL : templateImageUrl,
      status : formData.status,
      customizable_prompt: formData.promptTxt,
      category_1: formData.category1?.value || "null",
      category_2: formData.category2?.value || "null",
      category_3: formData.category3?.value || "null",
      category_4: formData.category4?.value || "null",
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'Template has been successfully updated!', 'success')
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }

   
  const { mutate: getTemplateByIdMutate, isLoading: getTemplateByIdIsLoading, } = useGetTemplateById()

  const [selectedTemplateDetails, setSelectedTemplateDetails] = useState<any>(null)


 // get template details by id
 const getTemplateById = () => {
  getTemplateByIdMutate({
    appId: params.appId?.toString() || '',
    templateId: params.templateId?.toString() || '',
   }, {
     onSuccess: ({ data: successData }: any) => {
      const tempData = successData.data[0]

      setFormData({
        promptTxt : tempData?.prompt,
        category1 : {label: tempData?.category_1, value:tempData?.category_1},
        category2 : {label: tempData?.category_2, value:tempData?.category_2},
        category3 : {label: tempData?.category_3, value:tempData?.category_3},
        category4 : {label: tempData?.category_4, value:tempData?.category_4},
        status : tempData?.status === 1,
      })
    
      setCoverImageUrl(tempData?.coverImage || '')
      setTemplateImageUrl(tempData?.imageURL || '')
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        notify('Session expired.', 'error')
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
}


 
 useEffect(() => {
  if(params.appId && params.templateId){
    getAppById(params.appId)
    getTemplateById()
  }
 }, [params.appId, params.templateId])

 /** handle on drop method */
 const onDropCoverImage = useCallback(
  (acceptedFiles: any) => {
   if(acceptedFiles?.length>0){
     handleUploadImage(acceptedFiles[0], setCoverImageUrl, notify, setCoverImageLoding, navigate)
   }else{
     notify('File not supported.', 'error')
   }
  },
  [],
)

const onDropTemplateImage = useCallback(
  (acceptedFiles: any) => {
   if(acceptedFiles?.length>0){
     handleUploadImage(acceptedFiles[0], setTemplateImageUrl, notify, setTemplateImageLoding, navigate)
   }else{
     notify('File not supported.', 'error')
   }
  },
  [],
)

 /** on file drop file size and type validate for template image*/
 const { getRootProps: getRootPropsCover, getInputProps: getInputPropsCover } = useDropzone({
  onDrop: onDropCoverImage,
  accept: {
    'image/jpeg': ['.jpeg', '.png', '.jpg'],
  },
  maxFiles: 1,
  maxSize: 5000000,
})

/** on file drop file size and type validate for template image*/
const { getRootProps: getRootPropsTemplate, getInputProps: getInputPropsTemplate } = useDropzone({
  onDrop: onDropTemplateImage,
  accept: {
    'image/jpeg': ['.jpeg', '.png', '.jpg'],
  },
  maxFiles: 1,
  maxSize: 5000000,
})

  return (
    <div className='w-full flex flex-col h-full'>
      <HeaderSection title="UPDATE TEMPLATE"  onClickBackButton={()=>{
        navigate('/template-manage')
      }}  />
       <div className="flex w-full h-full p-5 overflow-y-auto hidescrl">
        <div className="w-full h-full flex flex-col bg-white rounded-[30px] justify-start items-start overflow-y-auto hidescrl py-6 px-2 md:px-10">
          <div className="headings-font text-[26px] leading-tight font-medium">{app_name}</div>
          <div className="captions-font font-bold text-sm w-full leading-tight">{vendorName}</div>

          <div className="w-full flex mt-10">
            <div className="flex w-full">
              <div className="w-full flex">
                <div className="md:w-[250px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">Cover Image</div>
                <section className='container relative '>
                    {coverImageLoding &&
                      <div className='flex w-[170px] h-full justify-center items-center absolute rounded pt-10'>
                        <Loader appearance='black' className="!h-1 !w-1" />
                      </div>
                    }
                    <div 
                    {...getRootPropsCover({ className: 'dropzone' })}
                    >
                      <input {...getInputPropsCover()} />
                      
                     
                      <div className="relative w-fit">
                        {(!coverImageUrl || coverImageUrl==='' )&&
                        <div className="bg-white border-[2px] border-N-700 border-dashed flex justify-center items-center w-[170px] h-[170px] rounded cursor-pointer">
                          Upload
                        </div>
                        }
                        {coverImageUrl &&
                          <img
                            src={coverImageUrl}
                            alt='gt'
                            className='w-[170px] h-auto rounded border-[2px] border-N-400'
                          />
                        }
                        <i className="ri-add-circle-fill bottom-0 right-0 absolute text-[30px] leading-none bg-white rounded-full" />
                      </div>
                    </div>
                </section>
              </div>

              <div className="w-full flex">
                <div className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">Template Image</div>
                <section className='container relative '>
                    {templateImageLoding &&
                      <div className='flex w-[170px] h-full justify-center items-center absolute rounded pt-10'>
                        <Loader appearance='black' className="!h-1 !w-1" />
                      </div>
                    }
                    <div 
                    {...getRootPropsTemplate({ className: 'dropzone' })}
                    >
                      <input {...getInputPropsTemplate()} />
                      
                     
                      <div className="relative w-fit">
                        {(!templateImageUrl || templateImageUrl==='' )&&
                        <div className="bg-white border-[2px] border-N-700 border-dashed flex justify-center items-center w-[170px] h-[170px] rounded cursor-pointer">
                          Upload
                        </div>
                        }
                        {templateImageUrl &&
                          <img
                            src={templateImageUrl}
                            alt='gt'
                            className='w-[170px] h-auto rounded border-[2px] border-N-400'
                          />
                        }
                        <i className="ri-add-circle-fill bottom-0 right-0 absolute text-[30px] leading-none bg-white rounded-full" />
                      </div>
                    </div>
                </section>
              </div>
            </div>
          </div>

          <table cellPadding={5} className="w-full table-auto overflow-x-auto mt-5">
            <tbody className="w-full ">
              {category_1_name && <tr className="w-fit">
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">{capitalizeFirstLetter(category_1_name)}</td>
                <td>
                  <SimpleSelect options={category1?.list} 
                    className="w-[250px]" 
                    value={formData.category1 } 
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        category1: e
                      })
                    }}
                  />
                </td>
              </tr>}

              {category_2_name && <tr className="w-fit">
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">{capitalizeFirstLetter(category_2_name)}</td>
                <td>
                  <SimpleSelect 
                  options={category2?.list} 
                  className="w-[250px]"
                  value={formData?.category2} 
                  onChange={(e)=>{
                    setFormData({
                      ...formData,
                      category2: e
                    })
                  }}
                  />
                  </td>
              </tr>}

              {category_3_name && <tr className="w-fit">
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">{capitalizeFirstLetter(category_3_name)}</td>
                <td>
                  <SimpleSelect 
                    options={category3?.list} 
                    className="w-[250px]"
                    value={formData?.category3} 
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        category3: e
                      })
                    }}
                    />
                </td>
              </tr>}

              {category_4_name && <tr className="w-fit">
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">{capitalizeFirstLetter(category_4_name)}</td>
                <td>
                  
                <SimpleSelect 
                    options={category4?.list} 
                    className="w-[250px]"
                    value={formData.category4} 
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        category4: e
                      })
                    }}
                    />
                </td>
              </tr>}

              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">Status</td>
                <td>
                 <ToggleSwitch appearance="default" 
                  setValue={formData.status} 
                  onToggleClick={e=>{
                    setFormData({
                      ...formData,
                      status: e.target.checked
                    })
                  }}/>
                </td>
              </tr>

              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">Prompt</td>
                <td>
                  <InputTextArea
                      value={formData.promptTxt || ''} 
                      onChange={(e)=>{
                        setFormData({
                          ...formData,
                          promptTxt: e.target.value
                        })
                      }}
                      className="w-full md:max-w-[400px]"
                      theme="default"
                      placeholder="A ****[layers] Round Cake decorate with ****[items] ."
                      rows={5}
                    />

                </td>
              </tr>

            </tbody>
           </table> 

           <div className="w-full justify-center items-center flex mt-5">
            <Button
             appearance="outline" 
              disabled={
                formData.promptTxt === '' ||  
                (category_1_name && formData?.category1 === null) || 
                (category_2_name && formData?.category2 === null) || 
                (category_3_name && formData?.category3 === null) || 
                (category_4_name && formData?.category4 === null) ||
               coverImageUrl === '' ||
               templateImageUrl === ''
              }
              className="!border-[2px] w-[200px]"
             size='md' 
             isLoading={updateTemplateIsLoading}
              onClick={()=>{
                updateTemplate()
              }}>
                Update
              </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateTemplate