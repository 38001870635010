import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UpdateTemplateInterface {
  templateID: string
  appID: string
  coverImage: string
  template_imageURL: string
  status: boolean
  customizable_prompt: string
  category_1: string
  category_2: string
  category_3: string
  category_4: string
}

export const useUpdateTemplate = () => {
  const mutate = useMutation(({ 
    templateID,
    appID,
    coverImage,
    template_imageURL,
    status,
    customizable_prompt,
    category_1,
    category_2,
    category_3,
    category_4
     }: UpdateTemplateInterface) => {
    return postAPI('/external-api/update_templates', {
      templateID,
      appID,
      coverImage,
      template_imageURL,
      status,
      customizable_prompt,
      category_1,
      category_2,
      category_3,
      category_4
    })
  })

  return mutate
}

export default useUpdateTemplate
