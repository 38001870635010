import { HeaderSection } from "components/molecules"
import { useNavigate, useParams } from "react-router-dom"
import { InputTextArea, PageLoader, ToggleSwitch} from "components/atoms"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { useGetTemplateById } from "framework/api/methods"
import capitalizeFirstLetter from "utils/capitalizeFirstLetter"

export const TemplateDetails = () => {
  const navigate =useNavigate()
  const params = useParams()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }


  const category_1_name = sessionStorage.getItem('category_1_name')
  const category_2_name = sessionStorage.getItem('category_2_name')
  const category_3_name = sessionStorage.getItem('category_3_name')
  const category_4_name = sessionStorage.getItem('category_4_name')


  const { mutate: getTemplateByIdMutate, isLoading: getTemplateByIdIsLoading, } = useGetTemplateById()

  const [selectedTemplateDetails, setSelectedTemplateDetails] = useState<any>(null)


 // get template details by id
 const getTemplateById = () => {
  getTemplateByIdMutate({
    appId: params.appId?.toString() || '',
    templateId: params.templateId?.toString() || '',
   }, {
     onSuccess: ({ data: successData }: any) => {
      const tempData = successData.data[0]
      setSelectedTemplateDetails({
        app_id: tempData?.app_id,
        category_1: tempData?.category_1,
        category_2: tempData?.category_2,
        category_3: tempData?.category_3,
        category_4: tempData?.category_4,
        coverImage: tempData?.coverImage,
        imageURL: tempData?.imageURL,
        prompt : tempData?.prompt,
        status: tempData?.status === 1,
        templateID: tempData?.templateID,
      })
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        notify('Session expired.', 'error')
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
}

   
 useEffect(() => {
  if(params.appId && params.templateId){
    getTemplateById()
  }
 }, [params.appId, params.templateId])

  return (
    <div className='w-full flex flex-col h-full'>
      <HeaderSection title="TEMPLATE DETAILS"  onClickBackButton={()=>{
        navigate('/template-manage')
      }}  />
       <div className="flex w-full h-full p-5 overflow-y-auto hidescrl">
        <div className="w-full h-full flex flex-col bg-white rounded-[30px] justify-start items-start overflow-y-auto hidescrl py-4 px-2 md:px-6">
          {getTemplateByIdIsLoading && <div className="w-full flex justify-center items-center"><PageLoader size="md" className="w-full"/></div>}
          
          {selectedTemplateDetails && 
            <div className="flex flex-col w-full px-10">
              <div className="w-full flex gap-x-10">
                {selectedTemplateDetails.coverImage &&
                  <div className="w-1/2 flex flex-col justify-center items-center gap-y-2">
                    <div className="poppins-medium text-center w-[150px]">Cover Image</div>
                    <div>
                    <img src={selectedTemplateDetails.coverImage} alt="cover-img"  className="w-[150px] h-auto rounded"/>
                    </div>
                  </div>
                }

                {selectedTemplateDetails.imageURL &&
                  <div className="w-1/2 flex flex-col justify-center items-center gap-y-2">
                    <div className="poppins-medium text-center w-[150px]">Template Image</div>
                    <div>
                      <img src={selectedTemplateDetails.imageURL} alt="template-img"  className="w-[150px] h-auto rounded"/>
                    </div>
                  </div>
                }
              </div>

              <div className="mt-5 flex flex-col border-t border-t-N-400 py-2 gap-y-5 text-lg">
                {category_1_name &&
                  <div className="w-full justify-center items-center flex gap-x-5">
                    <div className="poppins-bold text-left w-[150px]">{capitalizeFirstLetter(category_1_name)}</div>
                    <div className="poppins-regular text-left w-[150px]">{selectedTemplateDetails.category_1}</div>
                  </div>
                }

                {category_2_name &&
                  <div className="w-full justify-center items-center flex gap-x-5">
                    <div className="poppins-bold text-left w-[150px]">{capitalizeFirstLetter(category_2_name)}</div>
                    <div className="poppins-regular text-left w-[150px]">{selectedTemplateDetails.category_2}</div>
                  </div>
                }

                {category_3_name &&
                  <div className="w-full justify-center items-center flex gap-x-5">
                    <div className="poppins-bold text-left w-[150px]">{capitalizeFirstLetter(category_3_name)}</div>
                    <div className="poppins-regular text-left w-[150px]">{selectedTemplateDetails.category_3}</div>
                  </div>
                }

                {category_4_name &&
                  <div className="w-full justify-center items-center flex gap-x-5">
                    <div className="poppins-bold text-left w-[170px]">{capitalizeFirstLetter(category_4_name)}</div>
                    <div className="poppins-regular text-left w-[150px]">{selectedTemplateDetails.category_4}</div>
                  </div>
                }

              
                <div className="w-full justify-center items-center flex gap-x-5">
                  <div className="poppins-bold text-right w-1/2">Template Status</div>
                  <div className="poppins-regular text-left w-1/2 flex ">
                    <ToggleSwitch setValue={selectedTemplateDetails?.status}/>
                  </div>
                </div>

                <div className="w-full justify-center items-center flex gap-x-5">
                  <div className="poppins-bold text-right w-fit">Prompt</div>
                  <div className="poppins-regular text-left w-[450px]">
                    <InputTextArea value={selectedTemplateDetails?.prompt} isDisabled theme="default"/>
                  </div>
                </div>
              
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default TemplateDetails