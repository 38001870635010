import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface CheckUserInterface {
  username: string
  password: string
}

export const useLogin = () => {
  const mutate = useMutation(({ username, password }: CheckUserInterface) => {
    return get(`/external-api/access_token?grant_type=password&username=${username}&password=${password}`, {
      headers: {
        'authorization': 'Bearer QUlfTWFya2V0cGxhY2VfQXBwMzAwMDpNYXJrZXRARDIwMjQhMzAwMA=='
      }
    })
  })

  return mutate
}

export default useLogin
