import { HeaderSection } from "components/molecules"
import AI from 'assets/images/AI.png'
import { useGetDashboardData } from "framework/api/methods"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"

export const Dashboard = () => {

  const navigate = useNavigate()
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  
  
  const [dashboardData, setDashboardData] = useState({
    total_apps : 0,
    total_downloads: 0,
    total_quotations: 0,
    total_ratings: 0,
    total_users: 0,
    total_views: 0,
  })
  
  const onSuccess =(data: any)=>{
   setDashboardData({
    total_apps : data?.total_apps || 0,
    total_downloads: data?.total_downloads || 0,
    total_quotations: data?.total_quotations || 0,
    total_ratings: data?.total_ratings || 0,
    total_users: data?.total_users || 0,
    total_views: data?.total_views || 0,
   })
  }

  const onError =({ response: { data: errData }, response }: any)=>{
    if(response?.status===405 || response?.status===403 || response?.status===501 ){
      notify('Session expired.', 'error')
      setTimeout(()=>{
        navigate('/auth/login')
        localStorage.clear()
      }, 2000);
    }else{
      notify(errData?.message || 'Something went wrong.', 'error')
    }
  }

  const token = localStorage.getItem('token');
  
  /** Get all cards API */
  const { refetch:getDashboardData, isLoading:getDashboardDataIsLoading, isRefetching:getDashboardDataIsRefetching } = useGetDashboardData(onSuccess,onError)
  
  useEffect(() =>{
    token && getDashboardData()
  },[token]);


  return (
    <div className='w-full'>
      <HeaderSection title="DASHBOARD" />

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 pt-10 px-10 pb-10 gap-y-5 md:gap-x-[50px] md:gap-y-8 overflow-y-scroll md:overflow-y-scroll h-[calc(100vh-80px)] styled-scroll">
        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-start items-start bg-white rounded-[30px] p-4">
            <div className="flex justify-start items-center h-fit w-full">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full"></div>
              <div className="inter-bold font-bold pl-1 w-[calc(100%-20px)] text-center">NUMBER OF VIEWS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[38px] leading-tight">
              {dashboardData?.total_views}
            </div>  
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-start items-start bg-white rounded-[30px] p-4">
            <div className="flex justify-start items-center h-fit w-full">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full"></div>
              <div className="inter-bold font-bold pl-1 w-[calc(100%-20px)] text-center">NUMBER OF USERS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[38px] leading-tight">
              {dashboardData?.total_users}
            </div>  
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-start items-start bg-white rounded-[30px] p-4">
            <div className="flex justify-start items-center h-fit w-full">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full"></div>
              <div className="inter-bold font-bold pl-1 w-[calc(100%-20px)] text-center">NUMBER OF APPS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[38px] leading-tight">
            {dashboardData?.total_apps}
            </div>  
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-start items-start bg-white rounded-[30px] p-4">
            <div className="flex justify-start items-center h-fit w-full">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full"></div>
              <div className="inter-bold font-bold flex pl-1 w-[calc(100%-20px)] text-center">NUMBER OF DOWNLOADS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[38px] leading-tight">
            {dashboardData?.total_downloads}
            </div>  
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-start items-start bg-white rounded-[30px] p-4">
            <div className="flex justify-start items-center h-fit w-full">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full"></div>
              <div className="inter-bold font-bold pl-1 w-[calc(100%-20px)] text-center">NUMBER OF QUOTATIONS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[38px] leading-tight">
            {dashboardData?.total_quotations}
            </div>  
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-start items-start bg-white rounded-[30px] p-4">
            <div className="flex justify-start items-center h-fit w-full">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full"></div>
              <div className="inter-bold font-bold pl-1 w-[calc(100%-20px)] text-center">NUMBER OF RATINGS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[38px] leading-tight">
            {dashboardData?.total_ratings}
            </div>  
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-center items-center bg-white rounded-[30px] p-4">
            <img src={AI} alt='AI' className='w-auto h-full'/>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Dashboard