import { FC } from 'react'
import CN from 'classnames'
import { Loader } from '../Loader'

export interface ButtonProps {
  className?: string | undefined
  disabled?: boolean | undefined
  isLoading?: boolean | undefined
  isCustomStyles?: boolean | undefined
  appearance?: 'default' | 'outline'| undefined
  onClick?: (e: any) => void | undefined
  isFullWidth?: boolean | undefined
  size?: 'xs' | 'sm' | 'md' | 'lg' | undefined
  [x: string]: any
}

export const Button: FC<ButtonProps> = ({
  children,
  disabled,
  className,
  isCustomStyles,
  appearance = 'outline',
  isLoading,
  onClick,
  isFullWidth,
  size,
}: ButtonProps) => {
  const ButtonClasses = !isCustomStyles
    ? CN(
        'button font-semibold rounded-full',
        className,
        {
          'bg-[#212121] hover:bg-[#424242] active:bg-[#212121] disabled:bg-[#9E9E9E] text-white':
            appearance === 'default',
          'hover:bg-[#9E9E9E] hover:bg-opacity-20 active:bg-white disabled:bg-white text-black border-[4px] border-[#212121] disabled:border-[#9E9E9E] disabled:text-[#616161]':
            appearance === 'outline' || !appearance,
        },
        {
          'cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
        },
        {
          'w-full': isFullWidth,
          'w-fit': !isFullWidth,
        },
        {
          'px-[26px] py-[10px] text-lg': size === 'lg',
          'px-[30px] py-2': size === 'md',
          'px-8 py-[6px] text-sm': size === 'sm',
          'px-4 py-2 text-xs': size === 'xs',
        },
      )
    : className
  return (
    <button onClick={onClick} disabled={disabled || isLoading} className={ButtonClasses}>
      <div className='relative w-full'>
        <div
          className={CN({
            'opacity-0 flex items-center justify-center': isLoading,
            'opacity-100 flex items-center justify-center': !isLoading,
          })}>
          {children}
        </div>
        {isLoading && (
          <div className='absolute translate-x-[-50%] translate-y-[-150%] left-[50%]'>
            <Loader />
          </div>
        )}
      </div>
    </button>
  )
}

Button.defaultProps = {
  className: undefined,
  appearance: 'default',
  onClick: undefined,
  isLoading: false,
  isFullWidth: false,
  size: 'md',
}

export default Button
