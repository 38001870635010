import { ColumnDef } from "@tanstack/react-table"
import { Rating } from "components/atoms"
import { HeaderSection, UITable } from "components/molecules"
import { useGetWalletList } from "framework/api/methods"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import formatDateTime from "utils/formatDateTime"

export const Wallet = () => {
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'username',
        header: 'Username',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'user_phone',
        header: 'Contact number',
        headerClassName: '!min-w-[220px]',
        cellClassName:'!min-w-[220px]',
        cell:   props => {
          const phone: any = props.getValue();
          return <div>
                  {phone?`+${phone}`: ''}
                 </div>
        },
      },
      {
        accessorKey: 'credit_count',
        header: 'Credit count',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'burn_credits_count',
        header: 'Spent credit count',
        headerClassName: '!min-w-[250px]',
        cellClassName:'!min-w-[250px]',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'burn_starpoints_count',
        header: 'Spent star point count',
        headerClassName: '!min-w-[250px]',
        cellClassName:'!min-w-[250px]',
        cell: info => info.getValue(),
      },
    ],
    []
  )

  const navigate = useNavigate()
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [paginationProps, setPaginationProps] = useState<any>({
    limit: 10,
    page: 1,
  })
  const [pageCount, setPageCount] = useState(0)
  const [tableData, setTableData] = useState([])
  
 
  const { mutate: getWalletListMutate, isLoading: getWalletListIsLoading, } = useGetWalletList()
  
  // get list
  const getWalletList = () => {
    getWalletListMutate({
      limit: paginationProps?.limit,
      page:  paginationProps?.page
    }, {
      onSuccess: ({ data: successData }: any) => {
        setTableData(successData?.data || [])
        setPageCount(successData.totalPages)
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }


  useEffect(() => {
    getWalletList()
  }, [paginationProps])
  

  return (
    <div className='w-full flex flex-col h-full relative'>
      <HeaderSection title="WALLET" />

      <div className="flex w-full h-full p-5 overflow-y-auto hidescrl">
        <div className="w-full h-full flex flex-col bg-white rounded-[30px] justify-start items-start overflow-y-auto hidescrl py-2 px-2 md:px-6">
          <div className="w-full h-full">
            <UITable 
              columns={columns}
              data={tableData}
              paginationProps={paginationProps}
              setPaginationProps={setPaginationProps}
              pageCount={pageCount}
              isLoading={getWalletListIsLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Wallet