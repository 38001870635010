import { FC } from 'react'
import AI from 'assets/images/AI.png'
import { ToastContainer } from 'react-toastify'

export interface AuthLayoutProps {
  className?: string | undefined
  [x: string]: any
}

export const AuthLayout: FC<AuthLayoutProps> = ({
  children,
}: AuthLayoutProps) => {
  return (
    <div className='flex flex-col md:flex-row w-full h-screen md:bg-[#252529]'>
      <div className='hidden md:flex w-2/5 h-full justify-center items-center flex-col'>
        <img src={AI} alt='preview1' className='lg:w-[340px] lg:h-[340px] md:w-[240px] md:h-[240px] '/>
        <div className='poppins-regular mt-[60px] lg:text-5xl md:text-4xl text-white'>AI Marketplace</div>
      </div>

      <div className='flex md:hidden w-full justify-center items-center flex-col px-5 bg-[#252529] pb-12 pt-20'>
        <img src={AI} alt='preview1' className='w-[150px] h-[150px]'/>
        <div className='poppins-regular mt-[40px] text-4xl text-white'>AI Marketplace</div>
      </div>

      <div className='flex w-full md:w-3/5 h-full bg-white md:rounded-l-[30px] justify-center md:items-center px-6 md:px-[100px] py-10'>
        {children}
      </div>

      <ToastContainer
            position='bottom-center'
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
    </div>
  )
}

AuthLayout.defaultProps = {
  className: undefined,
}

export default AuthLayout
