import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetAllTemplatesWithoutCategoryInterface {
  appId: string
}

export const useGetAllTemplatesWithoutCategory = () => {
  const mutate = useMutation(({
    appId,
  }: GetAllTemplatesWithoutCategoryInterface) => {
    return get(`/external-api/getAlltemplatesWithoutCat/${appId}`)
  })

  return mutate
}

export default useGetAllTemplatesWithoutCategory
