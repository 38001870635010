import { FC, useEffect, useRef } from 'react'
import {
  Column,
  ColumnDef,
  Table,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import CN from 'classnames'
import { SimpleSelect } from 'components/atoms'
import { useLottie } from 'lottie-react'
import loadingJson from 'assets/gif/loadingJson.json'
import emptyStateJson from 'assets/gif/empty.json'

export interface UITableProps {
  className?: string
  data: any
  columns: ColumnDef<any>[]
  [x: string]: any
}

export const UITable: FC<UITableProps> = ({
  className,
  data,
  columns,
  paginationProps,
  setPaginationProps,
  pageCount,
  isLoading,
}: UITableProps) => {
    const headerRef = useRef<HTMLDivElement>(null)
    const bodyRef = useRef<HTMLDivElement>(null)

  const table = useReactTable({
    columns,
    data,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  // Sync the scroll between the header and the body
  useEffect(() => {
    const headerScroll = headerRef.current
    const bodyScroll = bodyRef.current

    if (headerScroll && bodyScroll) {
      const syncScroll = (source: HTMLElement, target: HTMLElement) => {
        target.scrollLeft = source.scrollLeft
      }

      const handleBodyScroll = () => syncScroll(bodyScroll, headerScroll)
      const handleHeaderScroll = () => syncScroll(headerScroll, bodyScroll)

      bodyScroll.addEventListener('scroll', handleBodyScroll)
      headerScroll.addEventListener('scroll', handleHeaderScroll)

      // Clean up the event listeners
      return () => {
        bodyScroll.removeEventListener('scroll', handleBodyScroll)
        headerScroll.removeEventListener('scroll', handleHeaderScroll)
      }
    }
  }, [])

  const AnimatedLoadingView = () => {
    const options = {
      animationData: loadingJson,
      loop: true,
      autoplay: true,
    }

    const { View } = useLottie(options)

    return View
  }

  const AnimatedEmptyStateView = () => {
    const options = {
      animationData: emptyStateJson,
      loop: true,
      autoplay: true,
    }

    const { View } = useLottie(options)

    return View
  }


  return (
    <div className="mt-5 w-full border border-[#424242] rounded-md">
      {/* Scrollable header */}
      <div ref={headerRef} className="overflow-x-auto hidescrl bg-[#424242] rounded-t-md">
        <div className="inline-block min-w-full">
          <table className="w-full bg-[#424242] rounded-t-md">
            <thead className="bg-[#424242] rounded-t-md w-full text-white">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => (
                    <th
                      key={header.id}
                    //   colSpan={header.colSpan}
                      className={CN(
                        'py-1 text-left !min-w-[200px] !px-10',
                        header.column.columnDef.headerClassName // Apply custom header className
                      )}
                      style={{ width: header.getSize() }} // Dynamically set column width
                    >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          </table>
        </div>
      </div>

      {/* Scrollable body */}
      <div
        ref={bodyRef}
        className="!max-h-[58vh] min-h-[58vh] overflow-x-auto overflow-y-auto styled-scroll"
      >
        {!isLoading && data?.length > 0 &&
        <div className="inline-block min-w-full">
          <table className="bg-P-400 w-full">
            <tbody className="h-full w-full bg-white">
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="h-[50px] border-b border-x border-N-300 w-full table"
                >
                  {row.getVisibleCells().map((cell: any) => (
                    <td
                      key={cell.id}
                      className={CN("text-left px-10 !min-w-[200px]",cell.column.columnDef.cellClassName)}
                      style={{ width: cell.column.getSize() }} // Dynamically set cell width
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        }
        {isLoading && 
            <div className='w-full min-h-[50vh] flex justify-center items-center bg-white'>
              <div className='h-[20vh] flex'>
                <AnimatedLoadingView />
              </div>
            </div>
        }
        { !isLoading && data.length === 0 &&
          <div className='w-full min-h-[50vh] flex flex-col justify-center items-center bg-white'>
            <div className='h-[20vh] flex text-center justify-center items-center bg-R-40<0'>
              <div className='h-[20vh] flex'>
                <AnimatedEmptyStateView/>
                </div>
             </div>
             <span className='w-full text-center font-medium text-lg'>No data found.</span>
          </div>
        }
      </div>

      <div className="pagination-div border-t border-[#424242] flex justify-between items-center h-[50px] px-2 rounded-b-md">
        <div>
          <div className="flex flex-col md:flex-row w-full justify-start items-center gap-x-2">
            <div className="poppins-regular text-sm">Page size: </div>
            <SimpleSelect
              menuPlacement="top"
              value={{
                label: paginationProps.limit,
                value: paginationProps.limit,
              }}
              className="w-full md:w-[95px] rounded text-[15px]"
              borderRadius={2}
              height={20}
              onChange={(e) => {
                setPaginationProps({
                  ...paginationProps,
                  page: 1,
                  limit: e.value,
                })
              }}
              options={[
                {
                  label: 10,
                  value: 10,
                },
                {
                  label: 30,
                  value: 30,
                },
                {
                  label: 50,
                  value: 50,
                },
              ]}
            />
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <div
            className={CN("w-4 h-4 border border-N-400 rounded flex justify-center items-center p-3 hover:bg-N-100 cursor-pointer",{
                'cursor-not-allowed bg-N-300 hover:bg-N-300 text-N-400': paginationProps.page <= 1
            })}
            onClick={()=>{
                if(paginationProps.page > 1){
                    setPaginationProps({
                        ...paginationProps,
                        page: paginationProps.page-1
                    })
                }
            }}
          >
            <i className="ri-arrow-left-line" />
          </div>

          <span className="poppins-regular text-sm">
            Page {paginationProps.page} of {pageCount}
          </span>
          <div
            className={CN("w-4 h-4 border border-N-400 rounded flex justify-center items-center p-3 hover:bg-N-100 cursor-pointer",{
                'cursor-not-allowed bg-N-300 hover:bg-N-300 text-N-400': paginationProps.page === pageCount
            })}
            onClick={()=>{
                if(paginationProps.page < pageCount){
                    setPaginationProps({
                        ...paginationProps,
                        page: paginationProps.page+1
                    })
                    
                }
            }}
          >
            <i className="ri-arrow-right-line" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UITable