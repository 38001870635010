/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  breadcrumb: '',
}

const headerSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      ;(state.isLoading = false), (state.breadcrumb = '')
    },

    setIsLoadingState(state, { payload }) {
      state.isLoading = payload
    },

    setBreadcrumbState(state, { payload }) {
      state.breadcrumb = payload
    },
  },
})

export const { resetToInitialState, setIsLoadingState, setBreadcrumbState } =
  headerSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setIsLoading =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState(payload))
  }

export const setBreadcrumb =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setBreadcrumbState(value))
  }

export default headerSlice.reducer
