/* Layouts */
import { AuthLayout } from 'components/layouts/AuthLayout'
import { MainLayout } from 'components/layouts/MainLayout'
import { AppDetails } from 'components/pages/AppDetails'
import { AppsManager } from 'components/pages/AppsManager'
import { Login } from 'components/pages/auth'
import { CreateApp } from 'components/pages/CreateApp'
import { CreateTemplate } from 'components/pages/CreateTemplate'
import { Dashboard } from 'components/pages/Dashboard/Dashboard'
import { Downloads } from 'components/pages/Downloads'
import { Quotation } from 'components/pages/Quotation'
import { Ratings } from 'components/pages/Ratings'
import { TemplateDetails } from 'components/pages/TemplateDetails'
import { TemplateManager } from 'components/pages/TemplateManager'
import { UpdateApp } from 'components/pages/UpdateApp'
import { UpdateTemplate } from 'components/pages/UpdateTemplate'
import { User } from 'components/pages/User'
import { Wallet } from 'components/pages/Wallet'


interface Route {
  path: string
  component?: any
  layout?: any
  redirectTo?: string
  isSecured?: boolean
  isAuthScreen?: boolean
}

export const routes: Route[] = [
  {
    path: '/auth',
    redirectTo: '/auth/login',
  },
  {
    path: '/auth/login',
    component: Login,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  {
    path: '/',
    component: Dashboard,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/apps-manage',
    component: AppsManager,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/apps-manage/app-details/:id',
    component: AppDetails,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/apps-manage/create-app',
    component: CreateApp,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/apps-manage/update-app/:id',
    component: UpdateApp,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/template-manage',
    component: TemplateManager,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/template-manage/template-details/:appId/:templateId',
    component: TemplateDetails,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/template-manage/create-template/:appId',
    component: CreateTemplate,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/template-manage/update-template/:appId/:templateId',
    component: UpdateTemplate,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/ratings',
    component: Ratings,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/wallet',
    component: Wallet,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/users',
    component: User,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/quotations',
    component: Quotation,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/downloads',
    component: Downloads,
    layout: MainLayout,
    isSecured: true,
  },
  
  // {
  //   path: '*',
  //   component: Error404,
  //   isSecured: true,
  // },
]

export default routes
