import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetTemplateByIdInterface {
  appId: string
  templateId: string
}

export const useGetTemplateById = () => {
  const mutate = useMutation(({appId, templateId}: GetTemplateByIdInterface) => {
    return get(`/external-api/gettemplatebyId/${appId}/${templateId}`)
  })

  return mutate
}

export default useGetTemplateById
