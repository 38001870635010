import { useQuery } from "react-query";
import { get } from "framework/api/http";

/* Get all cards */
export const getDashboardData = async () => {
    const res = await get(`/external-api/get_dashboard_data`)
  
    return res?.data?.data
  }

/* Use Query as a Hook */
export const useGetDashboardData = (onSuccess: any, onError: any) => {
    return useQuery('dashboard-list', getDashboardData, {
      refetchOnWindowFocus: false,
      enabled: false,
      retry:1,
      onSuccess,
      onError,
    })
  }

export default useGetDashboardData;


