import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetRatingsListInterface {
  appId: string
  page: number
  limit: number
}

export const useGetRatingsListByAppId = () => {
  const mutate = useMutation(({appId, page, limit}: GetRatingsListInterface) => {
    return get(`/external-api/get_ratings/${appId}?page=${page}&limit=${limit}`)
  })

  return mutate
}

export default useGetRatingsListByAppId
