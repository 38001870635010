/* eslint-disable jsx-a11y/no-autofocus */
import { FC } from 'react'
import CN from 'classnames'

export interface InputTextAreaProps {
  className?: string | undefined
  inputClassName?: string
  isAutoFocus?: boolean
  isDisabled?: boolean
  isError?: boolean
  labelValue?: string | undefined
  onChange?: (e: any) => void | undefined
  onKeyPress?: (e: any) => void | undefined
  placeholder?: string
  rows?: number
  value?: any
  isRequired?: boolean
  theme?: 'default' | 'white'
}

export const InputTextArea: FC<InputTextAreaProps> = ({
  className,
  inputClassName,
  isAutoFocus,
  isDisabled,
  isError,
  labelValue,
  onChange,
  onKeyPress,
  placeholder,
  rows,
  value,
  isRequired,
  theme='white',
}: InputTextAreaProps) => {
  return (
    <div className={CN('flex flex-col w-full inter-medium', {}, className)}>
      {labelValue && (
        <span
          className={CN(
            {
              'text-N-700 pb-1': !isError,
              'text-R-500 pb-1': isError,
            },
          )}>
          {labelValue}

          {isRequired && <span className='text-R-500 ml-[2px]'>*</span>}
        </span>
      )}
      <textarea
        placeholder={placeholder}
        autoFocus={isAutoFocus}
        rows={rows}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        className={CN(
          'w-full outline-none rounded-[20px] py-[10px] px-4 text-base',
          { '!border-R-200': isError },
          {
            'bg-[#D1D1D1] border border-N-200': theme==='default',
            'bg-white shadow-md border border-black border-opacity-5': theme==='white',
          },
          inputClassName,
        )}
        disabled={isDisabled}
      />
    </div>
  )
}

InputTextArea.defaultProps = {
  className: undefined,
  inputClassName: undefined,
  isAutoFocus: false,
  isDisabled: false,
  isError: false,
  labelValue: undefined,
  onChange: undefined,
  onKeyPress: undefined,
  placeholder: undefined,
  rows: 3,
  value: undefined,
  isRequired: false,
}

export default InputTextArea
