import { Button, Modal, PageLoader, SimpleSelect, ToggleSwitch } from "components/atoms"
import { HeaderSection } from "components/molecules"
import { useDeleteTemplate, useGetAllApps, useGetAllTemplates, useGetAllTemplatesWithoutCategory, useGetAppById } from "framework/api/methods"
import AI from 'assets/images/AI.png'
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import CN from 'classnames'

export const TemplateManager = () => {
  const navigate = useNavigate()
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const sessionCat1 = sessionStorage.getItem('category_1')
  const sessionCat2 = sessionStorage.getItem('category_2')
  const sessionCat3 = sessionStorage.getItem('category_3')
  const sessionCat4 = sessionStorage.getItem('category_4')
  const sessionAppId = sessionStorage.getItem('temp-app-id')
  const sessionAppName = sessionStorage.getItem('temp-app-name')
  const sessionIsAll = sessionStorage.getItem('temp-is-all')
  

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [appList, setAppList] = useState([])
  const [selectedApp, setSelectedApp] = useState<any>(sessionAppId ? {
    label:sessionAppName,
    value: sessionAppId
  } : null)
  const [category1, setCategory1] = useState<any>({
    name: null,
    list: []
  })
  const [category2, setCategory2] = useState<any>({
    name: null,
    list: []
  })
  const [category3, setCategory3] = useState<any>({
    name: null,
    list: []
  })
  const [category4, setCategory4] = useState<any>({
    name: null,
    list: []
  })

  const [selectCategory1, setSelectCategory1] = useState<any>(sessionCat1? {
    value: sessionCat1,
    label: sessionCat1,
  }: null)
  const [selectCategory2, setSelectCategory2] = useState<any>(sessionCat2? {
    value: sessionCat2,
    label: sessionCat2,
  }: null)
  const [selectCategory3, setSelectCategory3] = useState<any>(sessionCat3? {
    value: sessionCat3,
    label: sessionCat3,
  }: null)
  const [selectCategory4, setSelectCategory4] = useState<any>(sessionCat4? {
    value: sessionCat4,
    label: sessionCat4,
  }: null)
  const [isAllSelected, setIsAllSelected] = useState(sessionIsAll==="false" ? false : true)
  const [templateList, setTemplateList] = useState<any>([])
  const [templateCount, setTemplateCount] = useState<any>(0)
  const [selectedTemplateId, setSelectedTemplateId] = useState<any>(null)
  
  

  const onSuccess =(data: any)=>{
    setAppList(data.map((item: any) => ({
      label: item.app_name,
      value: item.app_id
    })))
  }

  const onError =({ response: { data: errData }, response }: any)=>{
    if(response?.status===405 || response?.status===403 || response?.status===501 ){
      notify('Session expired.', 'error')
      setTimeout(()=>{
        navigate('/auth/login')
        localStorage.clear()
      }, 2000);
    }else{
      notify(errData?.message || 'Something went wrong.', 'error')
    }
  }

  const { refetch:getAppList } = useGetAllApps(onSuccess,onError)
  const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()
  const { mutate: getAllTemplatesWithoutCategoryMutate, isLoading: getAllTemplatesWithoutCategoryIsLoading, } = useGetAllTemplatesWithoutCategory()
  const { mutate: getAllTemplatesMutate, isLoading: getAllTemplatesIsLoading, } = useGetAllTemplates()
  



  
  // get all templates without category
  const getAllTemplatesWithoutCategory = (appId: string) => {
    getAllTemplatesWithoutCategoryMutate({
      appId
    }, {
      onSuccess: ({ data: successData }: any) => {
       setTemplateList(successData?.data || [])
       setTemplateCount(successData?.data?.length)
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }



  // get app details by id
  const getAppById = (appId: string) => {
    getAppByIdMutate({
      appId
    }, {
      onSuccess: ({ data: successData }: any) => {
      
        const data = successData?.data[0]
        setCategory1({
          name: JSON.parse(data.category_1)[0]?.name,
          list: JSON.parse(data.category_1)[0]?.data?.map((item: any) => ({
            label: item,
            value: item
          }))
        })

        setCategory2({
          name: JSON.parse(data.category_2)[0]?.name,
          list: JSON.parse(data.category_2)[0]?.data?.map((item: any) => ({
            label: item,
            value: item
          }))
        })


        setCategory3({
          name: JSON.parse(data.category_3)[0]?.name,
          list: JSON.parse(data.category_3)[0]?.data?.map((item: any) => ({
            label: item,
            value: item
          }))
        })

        setCategory4({
          name: JSON.parse(data.category_4)[0]?.name,
          list: JSON.parse(data.category_4)[0]?.data?.map((item: any) => ({
            label: item,
            value: item
          }))
        })

      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }

  // get all templates without category
  const getAllTemplates = (appId: string) => {
    getAllTemplatesMutate({
      appId,
      category_1: selectCategory1?.value || 'null',
      category_2: selectCategory2?.value || 'null',
      category_3: selectCategory3?.value || 'null',
      category_4: selectCategory4?.value || 'null',
    }, {
      onSuccess: ({ data: successData }: any) => {
       setTemplateList(successData?.data || [])
       setTemplateCount(successData?.data?.length)
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }



  useEffect(() => {
    selectedApp && getAppById(selectedApp?.value)
  },[selectedApp])



  const checkAndCallAPI = (isCat1Active: boolean, isCat2Active : boolean, isCat3Active : boolean, isCat4Active : boolean, cat1val : any, cat2val : any, cat3val : any, cat4val : any) => {
    const activeCategories = [
      { active: isCat1Active, value: cat1val },
      { active: isCat2Active, value: cat2val },
      { active: isCat3Active, value: cat3val },
      { active: isCat4Active, value: cat4val },
    ];
  
    // Count the number of active categories and the number of assigned values
    const activeCount = activeCategories.filter(cat => cat.active).length;
    const valueCount = activeCategories.filter(cat => cat.active && cat.value).length;
  
    // Call the API based on flexible conditions
    if (
      (activeCount === 1 && valueCount === 1) ||
      (activeCount === 2 && valueCount === 2) || 
      (activeCount === 3 && valueCount === 3) ||
      (activeCount === 4 && valueCount === 4) 
    ) {
      getAllTemplates(selectedApp.value)
    }
  }

  useEffect(() => {
    const isCat1Active = category1?.name ? true : false;
    const isCat2Active = category2?.name ? true : false;
    const isCat3Active = category3?.name ? true : false;
    const isCat4Active = category4?.name ? true : false;

  !isAllSelected && checkAndCallAPI(isCat1Active, isCat2Active, isCat3Active, isCat4Active , selectCategory1?.value , selectCategory2?.value , selectCategory3?.value, selectCategory4?.value)
  },[isAllSelected, category1, category2, category3, category4, selectCategory1, selectCategory2, selectCategory3, selectCategory4]);

  useEffect(() => {
    isAllSelected && selectedApp?.value && getAllTemplatesWithoutCategory(selectedApp?.value)
  }, [isAllSelected, selectedApp])
  

  const { mutate: deleteTemplateMutate, isLoading: deleteTemplateIsLoading } = useDeleteTemplate();

  //delete template
  const deleteTemplate = (templateId: any) => {
    deleteTemplateMutate({
      appID: selectedApp.value,
      templateId
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'template deleted!', 'success')
        if(isAllSelected){
          getAllTemplatesWithoutCategory(selectedApp.value)
        }else{
          getAllTemplates(selectedApp.value)
        } 
        setIsOpenDeleteModal(false)
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            sessionStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        setIsOpenDeleteModal(false)
      },
    })
  } 

  return (
    <div className='w-full flex flex-col h-full relative'>
      <HeaderSection title="TEMPLATE MANAGER" />

      <div className="flex w-full h-full p-5 overflow-y-auto hidescrl">
        <div className="w-full h-full flex flex-col bg-white rounded-[30px] justify-start items-start overflow-y-auto hidescrl py-4 px-2 md:px-6">
        
        <div className="flex justify-between items-center w-full">

         <div className="flex flex-col md:flex-row w-full justify-start items-center  gap-x-2">
            <div className="poppins-medium w-[95px]">Select App:</div>
            
            <SimpleSelect 
              placeholder="Select..."
              value={selectedApp}  
              className="w-full md:w-[300px]"
              onChange={(e)=>{
                setSelectedApp(e)
                setSelectCategory1(null)
                setSelectCategory2(null)
                setSelectCategory3(null)
                setSelectCategory4(null)
                setIsAllSelected(true)
                sessionStorage.clear()
              }}
              options={appList} 
            />

            {selectedApp &&
              <div className={CN("w-fit min-w-[200px] flex justify-start items-start gap-x-2 py-1 pl-3 poppins-medium text-sm cursor-pointer ")}>
                <div className="flex">View All: </div>
                <ToggleSwitch setValue={isAllSelected} onToggleClick={(e)=>{
                  setIsAllSelected(e.target.checked)
                  !e.target.checked && setTemplateList([])
                  setSelectCategory1(null)
                  setSelectCategory2(null)
                  setSelectCategory3(null)
                  setSelectCategory4(null)
                  sessionStorage.clear()
                  setTemplateCount(0)
                }}/>
              </div>
            }
          </div>

        {selectedApp && 
          <div className="flex flex-col w-full justify-start items-end">
            <div className="mt-2 poppins-medium text-sm w-fit text-right">Number of templates : {templateCount}</div>
          </div>}
        </div>
          


          {selectedApp && <div className="w-full flex flex-col ">
            {!isAllSelected &&
            <div className="flex flex-col w-full md:flex-row gap-x-10 gap-y-2 mt-5 items-end">
              <div className="flex flex-col w-full md:flex-row flex-1 gap-x-3">
                {category1?.name &&
                  <div className="flex flex-col w-full md:w-1/4 justify-center items-center gap-y-2">
                    <div className="poppins-medium text-sm">{category1?.name}</div>
                    
                    <SimpleSelect 
                      placeholder="Select..."
                      value={selectCategory1}  
                      className="w-full"
                      onChange={(e)=>{
                        setSelectCategory1(e)
                      }}
                      options={category1?.list}
                      height={32} 
                    />
                  </div>
                }

                {category2?.name &&
                  <div className="flex flex-col w-full md:w-1/4 justify-center items-center gap-y-2">
                    <div className="poppins-medium text-sm">{category2?.name}</div>
                    
                    <SimpleSelect 
                      placeholder="Select..."
                      value={selectCategory2}  
                      className="w-full"
                      onChange={(e)=>{
                        setSelectCategory2(e)
                      }}
                      height={32} 
                      options={category2?.list} 
                    />
                  </div>
                }

                {category3?.name &&
                  <div className="flex flex-col w-full md:w-1/4 justify-center items-center gap-y-2">
                    <div className="poppins-medium text-sm">{category3?.name}</div>
                    
                    <SimpleSelect 
                      placeholder="Select..."
                      value={selectCategory3}  
                      className="w-full"
                      onChange={(e)=>{
                        setSelectCategory3(e)
                      }}
                      height={32} 
                      options={category3?.list} 
                    />
                  </div>
                }

                {category4?.name &&
                  <div className="flex flex-col w-full md:w-1/4 justify-center items-center gap-y-2">
                    <div className="poppins-medium text-sm">{category4?.name}</div>
                    
                    <SimpleSelect 
                      placeholder="Select..."
                      value={selectCategory4}  
                      className="w-full"
                      onChange={(e)=>{
                        setSelectCategory4(e)
                      }}
                      height={32} 
                      options={category4?.list} 
                    />
                  </div>
                }
              </div>
            </div>}
          
            <div className="w-full grid grid-col-2 md:grid-cols-4 gap-3 mt-6 md:overflow-y-auto styled-scroll">
              {templateList?.map((template: any) => 
                <div className="w-full h-[200px] flex justify-center items-center relative cursor-pointer" key={template?.templateID} 
                 onClick={()=>{

                      category1?.name && sessionStorage.setItem('category_1', selectCategory1?.value)
                      category2?.name && sessionStorage.setItem('category_2', selectCategory2?.value)
                      category3?.name && sessionStorage.setItem('category_3', selectCategory3?.value)
                      category4?.name && sessionStorage.setItem('category_4', selectCategory4?.value)
                      sessionStorage.setItem('temp-app-id', selectedApp?.value)
                      sessionStorage.setItem('temp-app-name', selectedApp?.label)
                      sessionStorage.setItem('temp-is-all', isAllSelected?.toString())

                      
                      category1?.name && sessionStorage.setItem('category_1_name', category1?.name)
                      category2?.name && sessionStorage.setItem('category_2_name', category2?.name)
                      category3?.name && sessionStorage.setItem('category_3_name', category3?.name)
                      category4?.name && sessionStorage.setItem('category_4_name', category4?.name)

                      if(isAllSelected){
                        sessionStorage.removeItem('category_1')
                        sessionStorage.removeItem('category_2')
                        sessionStorage.removeItem('category_3')
                        sessionStorage.removeItem('category_4')
                      }
                      
                      navigate(`/template-manage/template-details/${selectedApp?.value}/${template?.templateID}`)
                 }}
                >
                  <img src={template?.coverImage} alt='preview1' className='w-auto h-full rounded-xl'/>
                
                  <div className='flex justify-center items-center py-2 h-[80px] lg:h-[50px] w-full gap-x-5 absolute bottom-0'>
                    <i className='ri-edit-box-line text-[16px] bg-white border rounded p-2 text-black leading-none' 
                    onClick={(e)=>{
                      e.stopPropagation();
                      category1?.name && sessionStorage.setItem('category_1', selectCategory1?.value)
                      category2?.name && sessionStorage.setItem('category_2', selectCategory2?.value)
                      category3?.name && sessionStorage.setItem('category_3', selectCategory3?.value)
                      category4?.name && sessionStorage.setItem('category_4', selectCategory4?.value)
                      sessionStorage.setItem('temp-app-id', selectedApp?.value)
                      sessionStorage.setItem('temp-app-name', selectedApp?.label)
                      sessionStorage.setItem('temp-is-all', isAllSelected?.toString())

                      if(isAllSelected){
                        sessionStorage.removeItem('category_1')
                        sessionStorage.removeItem('category_2')
                        sessionStorage.removeItem('category_3')
                        sessionStorage.removeItem('category_4')
                      }

                      category1?.name && sessionStorage.setItem('category_1_name', category1?.name)
                      category2?.name && sessionStorage.setItem('category_2_name', category2?.name)
                      category3?.name && sessionStorage.setItem('category_3_name', category3?.name)
                      category4?.name && sessionStorage.setItem('category_4_name', category4?.name)
                      
                      navigate(`/template-manage/update-template/${selectedApp?.value}/${template?.templateID}`)
                      // navigate('/dddd')
                    }}/>
                    
                    <i className='ri-delete-bin-5-line text-[16px] bg-white border rounded p-2 text-black leading-none' onClick={(e)=>{
                      e.stopPropagation();
                      setIsOpenDeleteModal(true)
                      setSelectedTemplateId(template.templateID)
                    }}/>
                  </div>
                </div>
              )}
            </div>
          </div>
          }

          {
            !selectedApp && <div className="w-full text-center mt-10 text-lg">No app selected. Please select an app to view the templates.</div>
          }
        </div>        
      </div>

    {selectedApp?.value && 
      <i
       className="ri-add-circle-fill text-[60px] md:text-[70px] text-[#252529] hover:text-[#212121] cursor-pointer absolute bottom-2 right-2 shadow-md rounded-full leading-none"
       onClick={()=>{
        navigate(`/template-manage/create-template/${selectedApp?.value}`)
        category1?.name && sessionStorage.setItem('category_1', selectCategory1?.value)
        category2?.name && sessionStorage.setItem('category_2', selectCategory2?.value)
        category3?.name && sessionStorage.setItem('category_3', selectCategory3?.value)
        category4?.name && sessionStorage.setItem('category_4', selectCategory4?.value)
        sessionStorage.setItem('temp-app-id', selectedApp?.value)
        sessionStorage.setItem('temp-app-name', selectedApp?.label)
        sessionStorage.setItem('temp-is-all', isAllSelected?.toString())

        category1?.name && sessionStorage.setItem('category_1_name', category1?.name)
        category2?.name && sessionStorage.setItem('category_2_name', category2?.name)
        category3?.name && sessionStorage.setItem('category_3_name', category3?.name)
        category4?.name && sessionStorage.setItem('category_4_name', category4?.name)

        if(isAllSelected){
          sessionStorage.removeItem('category_1')
          sessionStorage.removeItem('category_2')
          sessionStorage.removeItem('category_3')
          sessionStorage.removeItem('category_4')
        }
                      
       }}
      />
    }

      <Modal
          isActive={isOpenDeleteModal}
          primaryButtonProps={{
            className:'!w-[50%]',
          }}
          isFooterShow={false}
          onOverlayClick={() => {
              setIsOpenDeleteModal(false)
          }}
          className='z-[1000] p-10'
          secondaryButtonTitle=''
          footerButtonAlignment='center'
          isHeaderShow={false}
          primaryButtonTitle=''>
          <div className='flex flex-col justify-start items-center px-8 py-4 min-h-[20vh]'>
            <div className="text-lg poppins-regular text-center font-bold">
              A re you sure you want to delete this template?
            </div>
            <div className=" mt-3 w-full gap-x-3 flex justify-between items-center">
              <Button 
                appearance="outline" 
                className="!border-[2px] !h-[45px] w-1/2" size='md'
                onClick={() => {
                  setIsOpenDeleteModal(false);
                }}
                >Close</Button>

                <Button 
                appearance="default" 
                className="!border-[2px] !h-[45px] w-1/2" size='md'
                isLoading={deleteTemplateIsLoading}
                onClick={() => {
                  deleteTemplate(selectedTemplateId)
                }}
                >Ok</Button>
            </div>
          </div>
        </Modal>
    </div>
  )
}

export default TemplateManager