import { FC } from 'react'
import CN from 'classnames'

import { LeftNav } from 'components/atoms/LeftNav'
import { useGetInvalidation } from 'framework/api/methods'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export interface SideBarProps {
  [x: string]: any
  isFullMenu?: boolean
  firstRoute?: any
  secondRoute?: any
  menuItemList?: any
  userDetails?: any
}

export const SideBar: FC<SideBarProps> = ({
  className,
  isFullMenu,
  firstRoute,
  secondRoute,
  menuItemList,
  userDetails,
  ...restProps
}: SideBarProps) => {
  const SideBarClasses = CN(
    'main-layout__sidebar z-[1] fixed left-0 h-full border-r border-r-N-300 transition-all duration-200 lg:h-full lg:top-0 bg-N-100 hidden lg:flex lg:w-[20%]',
    className,
  )

  const navigate = useNavigate()
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  
 
  const { mutate: getInvalidationMutate } = useGetInvalidation()
  
  // get invalidation
  const getInvalidation = () => {
    getInvalidationMutate({
      id: ''
    },{
      onSuccess: () => {
        notify('Successfully invalidate.', 'success')
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }

  return (
    <div className={SideBarClasses} {...restProps}>
      <div className='flex flex-col items-center w-full h-full px-[10px] py-2 bg-[#252529]'>
        <LeftNav
          menuItemList={menuItemList}
          firstRoute={firstRoute || ''}
          secondRoute={secondRoute}
          userDetails={userDetails}
        />

        <div className='w-full text-white py-2 px-4 border rounded bg-[#424242] border-[#757575] hover:bg-[#616161] cursor-pointer'
         onClick={()=>{
          getInvalidation()
         }}
        >
          <i className='ri-reset-left-fill pr-2'/>
          Cache Invalidate
          </div>
      </div>
      
    </div>
  )
}

SideBar.defaultProps = {
  onClick: undefined,
  menuItemList: undefined,
  firstRoute: undefined,
  secondRoute: undefined,
}

export default SideBar
