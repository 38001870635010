import { Button, Modal, PageLoader } from "components/atoms"
import { AppDetailsCard, HeaderSection } from "components/molecules"
import { useDeleteApp, useGetAllApps } from "framework/api/methods"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Slider from "react-slick";


export const AppsManager = () => {
  const navigate = useNavigate()
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  
  
  const [appList, setAppList] = useState([])

  const onSuccess =(data: any)=>{
    setAppList(data)
  }

  const onError =({ response: { data: errData }, response }: any)=>{
    if(response?.status===405 || response?.status===403 || response?.status===501 ){
      notify('Session expired.', 'error')
      setTimeout(()=>{
        navigate('/auth/login')
        localStorage.clear()
      }, 2000);
    }else{
      notify(errData?.message || 'Something went wrong.', 'error')
    }
  }

  
  /** Get all cards API */
  const { refetch:getAppList, isLoading:getAppListIsLoading, isRefetching:getAppListIsRefetching } = useGetAllApps(onSuccess,onError)
  

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [selectedAppId, setSelectedAppId] = useState<string|null>(null)
  const [selectedAppName, setSelectedAppName] = useState<string|null>(null)


  const { mutate: deleteAppMutate, isLoading: deleteAppIsLoading } = useDeleteApp();

  //delete app
  const deleteApp = (appID: any) => {
    deleteAppMutate({
      appID,
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'App deleted!', 'success')
        getAppList()
        setIsOpenDeleteModal(false)
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        setIsOpenDeleteModal(false)
      },
    })
  }
  
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  
  return (
    <div className='w-full flex flex-col h-full relative'>
      <HeaderSection title="APPS MANAGER" />

      {(getAppListIsLoading || getAppListIsRefetching) && <div className="w-full flex justify-center items-center"><PageLoader size="md" className="w-full"/></div>}
      
      {/* for PC */}
      <div className="w-full md:w-[80%] lg:w-[92%] mx-auto mt-[2%] p-1 h-[87%]">
        <div className="slider-container h-[87%]">
            <Slider {...settings} className="h-[87%]">
             {appList?.map((app: any, index) =>
             <div className="flex w-full h-full px-2">
                <AppDetailsCard 
                key={index}
                onCardClick={()=>{navigate(`/apps-manage/app-details/${app.app_id}`)}} 
                onEditClick={()=>{
                  navigate(`/apps-manage/update-app/${app.app_id}`)
                }}
                onDeleteClick={()=>{
                  setIsOpenDeleteModal(true)
                  setSelectedAppId(app.app_id)
                  setSelectedAppName(app.app_name)
                }}
                appId={app.app_id}
                appImage={app.app_icon}
                appName={app.app_name}
                noOfViews={app.views_count}
                rating={app.rating_count}
                average_rating={app.average_rating}
                c1={JSON.parse(app.category_1)[0]?.data}
                c2={JSON.parse(app.category_2)[0]?.data}
                c3={JSON.parse(app.category_3)[0]?.data}
                c4={JSON.parse(app.category_3)[0]?.data}
                c1Name={JSON.parse(app.category_1)[0]?.name}
                c2Name={JSON.parse(app.category_2)[0]?.name}
                c3Name={JSON.parse(app.category_3)[0]?.name}
                c4Name={JSON.parse(app.category_4)[0]?.name}
                status={app.status}
                />
              </div>
              )}
            </Slider>
          </div>
          </div>

        

     

      <i
       className="ri-add-circle-fill text-[60px] md:text-[70px] text-[#252529] hover:text-[#212121] cursor-pointer absolute bottom-2 right-2 shadow-md rounded-full leading-none"
       onClick={()=>{
        navigate('/apps-manage/create-app')
       }}
      />

      <Modal
          isActive={isOpenDeleteModal}
          primaryButtonProps={{
            className:'!w-[50%]',
          }}
          isFooterShow={false}
          onOverlayClick={() => {
              setIsOpenDeleteModal(false)
          }}
          className='z-[1000] p-10'
          secondaryButtonTitle=''
          footerButtonAlignment='center'
          isHeaderShow={false}
          primaryButtonTitle=''>
          <div className='flex flex-col justify-start items-center px-8 py-4 min-h-[20vh]'>
            <div className="text-lg poppins-regular text-center font-bold">
              A re you sure you want to delete {selectedAppName}?
            </div>
            <div className=" mt-3 w-full gap-x-3 flex justify-between items-center">
              <Button 
                appearance="outline" 
                className="!border-[2px] !h-[45px] w-1/2" size='md'
                onClick={() => {
                  setIsOpenDeleteModal(false);
                }}
                >Close</Button>

                <Button 
                appearance="default" 
                className="!border-[2px] !h-[45px] w-1/2" size='md'
                isLoading={deleteAppIsLoading}
                onClick={() => {
                  deleteApp(selectedAppId)
                }}
                >Ok</Button>
            </div>
          </div>
        </Modal>
    </div>
  )
}

export default AppsManager