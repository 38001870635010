import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface DeleteTemplateInterface {
  appID: any
  templateId: any
}

export const useDeleteTemplate = () => {
  const mutate = useMutation(({ 
    appID,
    templateId
     }: DeleteTemplateInterface) => {
    return get(`/external-api/removetemplate/${appID}/${templateId}`,)
  })

  return mutate
}

export default useDeleteTemplate
