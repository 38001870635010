import React from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import routes from 'routes/routes'

import { AuthRequired } from 'components/auth/AuthRequired'

const App: React.FC = () => {
  /* Render component with Layout */
  const renderWithLayout = (Component: any, Layout?: any) => {
    if (Layout) {
      return (
        <Layout>
          <Component />
        </Layout>
      )
    }

    return <Component />
  }

  /* Rendering routes with layout */
  const renderComponent = (
    Component: any,
    Layout?: any,
    isSecured?: boolean,
  ) => {
    if (isSecured) {
      return <AuthRequired>{renderWithLayout(Component, Layout)}</AuthRequired>
    } else {
      return renderWithLayout(Component, Layout)
    }
  }

  /* Iterate through Routes */
  const routeRender = (
    <Routes>
      {routes.map(
        (
          {
            redirectTo,
            path,
            layout: Layout,
            component: Component,
            isSecured,
          }: any,
          key,
        ) => {
          /* If redirectTo is defined, render a Redirect component */
          if (redirectTo) {
            return (
              <Route
                key={key}
                path={path}
                element={<Navigate to={redirectTo} />}
              />
            )
          }

          /* Render Route component */
          return (
            <Route
              path={path}
              key={key}
              element={renderComponent(Component, Layout, isSecured)}
            />
          )
        },
      )}
    </Routes>
  )

  return <Router>{routeRender}</Router>
}

export default App
