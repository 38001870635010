import { FC } from 'react'
import CN from 'classnames'

export interface ToggleSwitchProps {
  className?: string | undefined
  setValue?: boolean
  onToggleClick?: (e: any) => void
  text?: string | undefined
  appearance?: 'default' | 'mini' | undefined
  isDisabled?: boolean
  [x: string]: any
}

export const ToggleSwitch: FC<ToggleSwitchProps> = ({
  setValue,
  className,
  onToggleClick,
  appearance,
  isDisabled,
  text,
  ...restProps
}: ToggleSwitchProps) => {
  const ToggleSwitchClasses = CN('ToggleSwitchClasses flex', className, {})
  const elementID = Math.random().toString()
  return (
    <div className={ToggleSwitchClasses} {...restProps}>
      <label htmlFor={elementID} className='flex items-center cursor-pointer'>
        <div className='relative'>
          <input
            type='checkbox'
            id={elementID}
            className='sr-only'
            onChange={onToggleClick}
            checked={setValue}
            disabled={isDisabled}
          />
          <div
            className={CN(
              'block rounded-full',
              {
                'bg-[#212121] w-[34px] h-5':
                  'default' === appearance || !appearance,
                'bg-[#212121] w-[26px] h-4 ': 'mini' === appearance,
              },
              {
                'bg-[#212121]': setValue,
                'bg-[#D1D1D1]': !setValue,
              },
              {
                'opacity-50 cursor-not-allowed': isDisabled,
              },
            )}></div>
          <div
            className={CN(
              'dot absolute left-[1px] top-[2px] bg-white rounded-full transition',
              {
                'w-4 h-4': 'default' === appearance || !appearance,
                'w-3 h-3': 'mini' === appearance,
              },
              {
                'translate-x-full': setValue,
              },
            )}></div>
        </div>
        
        {text &&
        <div
          className={CN('ml-2 text-N-700', {
            'text-base': 'default' === appearance || !appearance,
            'text-xs': 'mini' === appearance,
          })}>
          {text}
        </div>}
      </label>
    </div>
  )
}

ToggleSwitch.defaultProps = {
  className: undefined,
  setValue: undefined,
  onToggleClick: undefined,
  text: undefined,
  appearance: 'default',
  isDisabled: false,
}

export default ToggleSwitch
