import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UpdateAppInterface {
  appId: string | any
  icon: string;
  AppName: string;
  venderName: string;
  shortDescription: string;
  coverImages: string[];
  appType: string;
  Appstatus: string;
  AppDescription: string;
  supplierContact: string;
  supplierEmail: string;
  Cat1: any
  Cat2: any
  Cat3: any
  Cat4: any
}

export const useUpdateApp  = () => {
  const mutate = useMutation(({ 
    appId,
    icon,
    AppName,
    venderName,
    shortDescription,
    coverImages,
    appType,
    Appstatus,
    AppDescription,
    supplierContact,
    supplierEmail,
    Cat1,
    Cat2,
    Cat3,
    Cat4
     }: UpdateAppInterface) => {
    return postAPI(`/external-api/update_app/${appId}`, {
      icon,
      AppName,
      venderName,
      shortDescription,
      coverImages,
      appType,
      Appstatus,
      AppDescription,
      supplierContact,
      supplierEmail,
      Cat1,
      Cat2,
      Cat3,
      Cat4
    })
  })

  return mutate
}

export default useUpdateApp 
