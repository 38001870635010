import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetWalletListInterface {
  page: number
  limit: number
}

export const useGetWalletList = () => {
  const mutate = useMutation(({page, limit}: GetWalletListInterface) => {
    return get(`/external-api/getCredits?page=${page}&limit=${limit}`)
  })

  return mutate
}

export default useGetWalletList
