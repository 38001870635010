import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddNewAppInterface {
  icon: string;
  AppName: string;
  venderName: string;
  shortDescription: string;
  coverImages: string[];
  appType: string;
  Appstatus: string;
  AppDescription: string;
  supplierContact: string;
  supplierEmail: string;
  Cat1: any
  Cat2: any
  Cat3: any
  Cat4: any
}

export const useAddNewApp = () => {
  const mutate = useMutation(({ 
    icon,
    AppName,
    venderName,
    shortDescription,
    coverImages,
    appType,
    Appstatus,
    AppDescription,
    supplierContact,
    supplierEmail,
    Cat1,
    Cat2,
    Cat3,
    Cat4
     }: AddNewAppInterface) => {
    return postAPI('/external-api/add_app', {
      icon,
    AppName,
    venderName,
    shortDescription,
    coverImages,
    appType,
    Appstatus,
    AppDescription,
    supplierContact,
    supplierEmail,
    Cat1,
    Cat2,
    Cat3,
    Cat4
    })
  })

  return mutate
}

export default useAddNewApp
