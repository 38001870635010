import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

export const useGetInvalidation = () => {
  const mutate = useMutation(({id}: any) => {
    return get(`/external-api/invalidation_send`)
  })

  return mutate
}

export default useGetInvalidation
