import { HeaderSection } from "components/molecules"
import { useNavigate } from "react-router-dom"
import { Button, Input, InputTextArea, Loader, SimpleSelect, ToggleSwitch } from "components/atoms"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { useDropzone } from "react-dropzone"
import { useAddNewApp, handleUploadImage } from "framework/api/methods"
import { isEmail, isPhoneNumber } from "utils"

export const CreateApp = () => {
  const navigate =useNavigate()
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [appIconUrl, setAppIconUrl] = useState('')
  const [coverImage1Url, setCoverImage1Url] = useState('')
  const [coverImage2Url, setCoverImage2Url] = useState('')
  const [coverImage3Url, setCoverImage3Url] = useState('')
  const [isCover1ImageLoading, setIsCover1ImageLoading] = useState(false)
  const [isCover2ImageLoading, setIsCover2ImageLoading] = useState(false)
  const [isCover3ImageLoading, setIsCover3ImageLoading] = useState(false)
  
  
  const [isAppIconLoading, setIsAppIconLoading] = useState(false)
  const [formData, setFormData] = useState<any>({
    appName:'',
    venderName:'',
    shortDescription:'',
    longDescription:'',
    appType: {
              label:'Designer',
              value:'designer'
            },
    status: true,
    supplierContact:'',
    supplierEmail:'',
    cat1: [{
      name:'',
      data: [],
    }],
    cat2: [{
      name:'',
      data: [],
    }],
    cat3: [{
      name:'',
      data: [],
    }],
    cat4: [{
      name:'',
      data: [],
    }],
  })

  const [tempCat1Item, setTempCat1Item] = useState('');
  const [tempCat2Item, setTempCat2Item] = useState('');
  const [tempCat3Item, setTempCat3Item] = useState('');
  const [tempCat4Item, setTempCat4Item] = useState('');

   /** handle on drop method */
   const onDrop = useCallback(
    (acceptedFiles: any) => {
     if(acceptedFiles?.length>0){
       handleUploadImage(acceptedFiles[0], setAppIconUrl, notify, setIsAppIconLoading, navigate)
     }else{
       notify('File not supported.', 'error')
     }
    },
    [],
  )

  const onDropCoverImage1 = useCallback(
    (acceptedFiles: any) => {
     if(acceptedFiles?.length>0){
       handleUploadImage(acceptedFiles[0], setCoverImage1Url, notify, setIsCover1ImageLoading, navigate)
     }else{
       notify('File not supported.', 'error')
     }
    },
    [],
  )

  const onDropCoverImage2 = useCallback(
    (acceptedFiles: any) => {
     if(acceptedFiles?.length>0){
       handleUploadImage(acceptedFiles[0], setCoverImage2Url, notify, setIsCover2ImageLoading, navigate)
     }else{
       notify('File not supported.', 'error')
     }
    },
    [],
  )

  const onDropCoverImage3 = useCallback(
    (acceptedFiles: any) => {
     if(acceptedFiles?.length>0){
       handleUploadImage(acceptedFiles[0], setCoverImage3Url, notify, setIsCover3ImageLoading, navigate)
     }else{
       notify('File not supported.', 'error')
     }
    },
    [],
  )
  
   /** on file drop file size and type validate for template image*/
   const { getRootProps: getRootPropsTemplate, getInputProps: getInputPropsTemplate } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg'],
    },
    maxFiles: 1,
    maxSize: 5000000,
  })
  
  /** on file drop file size and type validate for template image*/
  const { getRootProps: getRootPropsCoverImg1, getInputProps: getInputPropsCoverImg1 } = useDropzone({
    onDrop: onDropCoverImage1,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg'],
    },
    maxFiles: 1,
    maxSize: 5000000,
  })

  /** on file drop file size and type validate for template image*/
  const { getRootProps: getRootPropsCoverImg2, getInputProps: getInputPropsCoverImg2 } = useDropzone({
    onDrop: onDropCoverImage2,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg'],
    },
    maxFiles: 1,
    maxSize: 5000000,
  })

  /** on file drop file size and type validate for template image*/
  const { getRootProps: getRootPropsCoverImg3, getInputProps: getInputPropsCoverImg3 } = useDropzone({
    onDrop: onDropCoverImage3,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg'],
    },
    maxFiles: 1,
    maxSize: 5000000,
  })
  

  const { mutate: addNewAppMutate, isLoading: addNewAppIsLoading } = useAddNewApp()

  //add new app api
  const addNewApp = () => {
    addNewAppMutate({
      icon : appIconUrl,
      AppName: formData.appName,
      venderName: formData.venderName,
      shortDescription: formData.shortDescription,
      coverImages: [coverImage1Url, coverImage2Url, coverImage3Url],
      appType: formData.appType.value,
      Appstatus : formData.status ? 'true' : 'false',
      AppDescription  : formData.longDescription,
      supplierContact: formData.supplierContact,
      supplierEmail: formData.supplierEmail,
      Cat1 : formData.cat1?.[0]?.name !== "" ?  formData.cat1 : [],
      Cat2: formData.cat2?.[0]?.name !== "" ?  formData.cat2 : [],
      Cat3: formData.cat3?.[0]?.name !== "" ?  formData.cat3 : [],
      Cat4: formData.cat4?.[0]?.name !== "" ?  formData.cat4 : []
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'New App has been successfully Added!', 'success')
        resetFields()
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          notify('Session expired.', 'error')
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }

  

  const resetFields = () => {
    setAppIconUrl('')
    setCoverImage1Url('')
    setCoverImage2Url('')
    setCoverImage3Url('')
    setFormData({
      appName:'',
      venderName:'',
      shortDescription:'',
      longDescription:'',
      appType: {
                label:'Designer',
                value:'designer'
              },
      status: true,
      supplierContact:'',
      supplierEmail:'',
      cat1: [{
        name:'',
        data: [],
      }],
      cat2: [{
        name:'',
        data: [],
      }],
      cat3: [{
        name:'',
        data: [],
      }],
      cat4: [{
        name:'',
        data: [],
      }],
    })
  }

  return (
    <div className='w-full flex flex-col h-full'>
      <HeaderSection title="CREATE YOUR APP"  onClickBackButton={()=>{
        navigate('/apps-manage')
      }}  />
      
      <div className="flex w-full h-full p-5 overflow-y-auto hidescrl">
        <div className="w-full h-full flex flex-col bg-white rounded-[30px] justify-start items-start overflow-y-auto hidescrl py-4 px-2 md:px-6">
          <table cellPadding={5} className="w-full table-auto overflow-x-auto">
            <tbody>
              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">App Icon</td>
                <td>
                  <section className='container relative '>
                    {isAppIconLoading &&
                      <div className='flex w-[100px] h-full justify-center items-center absolute rounded pt-10'>
                        <Loader appearance='black' className="!h-1 !w-1" />
                      </div>
                    }
                    <div 
                    {...getRootPropsTemplate({ className: 'dropzone' })}
                    >
                      <input {...getInputPropsTemplate()} />
                      
                     
                      <div className="relative w-fit">
                        {(!appIconUrl || appIconUrl==='' )&&
                        <div className="bg-white border-[2px] border-N-700 border-dashed flex justify-center items-center w-[100px] h-[100px] rounded-full cursor-pointer">
                          Upload
                        </div>
                        }
                        {appIconUrl &&
                          <img
                            src={appIconUrl}
                            alt='gt'
                            className='w-[100px] h-[100px] rounded-full border-[4px] border-N-400'
                          />
                        }
                        <i className="ri-add-circle-fill bottom-0 right-0 absolute text-[30px] leading-none bg-white rounded-full" />
                      </div>
                    </div>
                  </section>
                </td>
              </tr>

              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">App Name</td>
                <td>
                  <Input
                    value={formData?.appName} 
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        appName: e.target.value
                      })
                    }} 
                    className="w-full md:max-w-[300px]"
                    theme="default"
                  />
                </td>
              </tr>

              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">Vender Name</td>
                <td>
                  <Input
                    value={formData.venderName} 
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        venderName: e.target.value
                      })
                    }} 
                    className="w-full md:max-w-[300px]"
                    theme="default"
                  />
                </td>
              </tr>

              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">Short Description</td>
                <td>
                  <Input
                    value={formData.shortDescription} 
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        shortDescription: e.target.value
                      })
                    }} 
                    className="w-full md:max-w-[300px]"
                    theme="default"
                  />
                </td>
              </tr>

              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">Long Description</td>
                <td>
                  <InputTextArea
                    value={formData.longDescription} 
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        longDescription: e.target.value
                      })
                    }}
                    className="w-full md:max-w-[300px]"
                    theme="default"
                  />
                </td>
              </tr>

              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">App Status</td>
                <td>
                 <ToggleSwitch appearance="default" setValue={formData.status} onToggleClick={e=>{
                  setFormData({
                    ...formData,
                    status: e.target.checked
                  })
                 }}/>
                </td>
              </tr>

              <tr className="md:table-row hidden">
              <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">Cover Images</td>
                <td className="flex gap-x-2 w-fit">
                  <section className='container relative '>
                    {isCover1ImageLoading &&  
                    <div className='flex w-full h-full justify-center items-center absolute'>
                      <Loader appearance='default'className="!bg-white" />
                    </div>
                    }

                    <div 
                    {...getRootPropsCoverImg1({ className: 'dropzone' })}
                    >
                      <input {...getInputPropsCoverImg1()} />

                     <div className="relative w-fit">
                        {(!coverImage1Url || coverImage1Url==='' )&&
                        <div  className='flex justify-center items-center w-[100px] h-[160px] rounded border border-dashed  cursor-pointer'>
                          <i className="ri-upload-2-line text-[25px]"/>
                        </div>
                        }

                        {coverImage1Url &&
                          <img
                            src={coverImage1Url}
                            alt='gt'
                            className='w-[100px] h-[160px] rounded'
                          />
                        }
                        {coverImage1Url &&
                          <i className="ri-add-line bottom-1 right-1 absolute text-[30px] leading-none bg-white rounded" />
                        }
                      </div>
                    </div>
                  </section>

                  <section className='container relative '>
                    {isCover2ImageLoading &&  
                    <div className='flex w-full h-full justify-center items-center absolute'>
                      <Loader appearance='default'className="!bg-white" />
                    </div>
                    }

                    <div 
                    {...getRootPropsCoverImg2({ className: 'dropzone' })}
                    >
                      <input {...getInputPropsCoverImg2()} />

                     <div className="relative w-fit">
                        {(!coverImage2Url || coverImage2Url==='' )&&
                        <div  className='flex justify-center items-center w-[100px] h-[160px] rounded border border-dashed  cursor-pointer'>
                          <i className="ri-upload-2-line text-[25px]"/>
                        </div>
                        }

                        {coverImage2Url &&
                          <img
                            src={coverImage2Url}
                            alt='gt'
                            className='w-[100px] h-[160px] rounded'
                          />
                        }
                        {coverImage2Url &&
                          <i className="ri-add-line bottom-1 right-1 absolute text-[30px] leading-none bg-white rounded" />
                        }
                      </div>
                    </div>
                  </section>

                  <section className='container relative '>
                    {isCover3ImageLoading &&  
                    <div className='flex w-full h-full justify-center items-center absolute'>
                      <Loader appearance='default'className="!bg-white" />
                    </div>
                    }

                    <div 
                    {...getRootPropsCoverImg3({ className: 'dropzone' })}
                    >
                      <input {...getInputPropsCoverImg3()} />

                     <div className="relative w-fit">
                        {(!coverImage3Url || coverImage3Url==='' )&&
                        <div  className='flex justify-center items-center w-[100px] h-[160px] rounded border border-dashed  cursor-pointer'>
                          <i className="ri-upload-2-line text-[25px]"/>
                        </div>
                        }

                        {coverImage3Url &&
                          <img
                            src={coverImage3Url}
                            alt='gt'
                            className='w-[100px] h-[160px] rounded'
                          />
                        }
                        {coverImage3Url &&
                          <i className="ri-add-line bottom-1 right-1 absolute text-[30px] leading-none bg-white rounded" />
                        }
                      </div>
                    </div>
                  </section>
                </td>
                
              </tr>
            </tbody>
          </table>

          <div className="flex flex-col md:hidden w-full">
            <div className="w-full poppins-regular text-[16px] text-[#1D1D1F] pt-2">Cover Images</div>
            <div className="flex gap-x-2 w-full px-[2px]">
              <section className='container relative w-1/3'>
                {isCover1ImageLoading &&  
                    <div className='flex w-full h-full justify-center items-center absolute'>
                      <Loader appearance='default'className="!bg-white" />
                    </div>
                    }

                    <div 
                    {...getRootPropsCoverImg1({ className: 'dropzone' })}
                    >
                      <input {...getInputPropsCoverImg1()} />

                     <div className="relative w-fit">
                        {(!coverImage1Url || coverImage1Url==='' )&&
                        <div  className='flex justify-center items-center w-[100px] h-[160px] rounded border border-dashed  cursor-pointer'>
                          <i className="ri-upload-2-line text-[25px]"/>
                        </div>
                        }

                        {coverImage1Url &&
                          <img
                            src={coverImage1Url}
                            alt='gt'
                            className='w-[100px] h-[160px] rounded'
                          />
                        }
                        {coverImage1Url &&
                          <i className="ri-add-line bottom-1 right-1 absolute text-[30px] leading-none bg-white rounded" />
                        }
                      </div>
                    </div>
              </section>

              <section className='container relative w-1/3'>
                {isCover2ImageLoading &&  
                    <div className='flex w-full h-full justify-center items-center absolute'>
                      <Loader appearance='default'className="!bg-white" />
                    </div>
                    }

                    <div 
                    {...getRootPropsCoverImg2({ className: 'dropzone' })}
                    >
                      <input {...getInputPropsCoverImg2()} />

                     <div className="relative w-fit">
                        {(!coverImage2Url || coverImage2Url==='' )&&
                        <div  className='flex justify-center items-center w-[100px] h-[160px] rounded border border-dashed  cursor-pointer'>
                          <i className="ri-upload-2-line text-[25px]"/>
                        </div>
                        }

                        {coverImage2Url &&
                          <img
                            src={coverImage2Url}
                            alt='gt'
                            className='w-[100px] h-[160px] rounded'
                          />
                        }
                        {coverImage2Url &&
                          <i className="ri-add-line bottom-1 right-1 absolute text-[30px] leading-none bg-white rounded" />
                        }
                      </div>
                    </div>
                </section>

              <section className='container relative w-1/3'>
                {isCover3ImageLoading &&  
                    <div className='flex w-full h-full justify-center items-center absolute'>
                      <Loader appearance='default'className="!bg-white" />
                    </div>
                    }

                    <div 
                    {...getRootPropsCoverImg3({ className: 'dropzone' })}
                    >
                      <input {...getInputPropsCoverImg3()} />

                     <div className="relative w-fit">
                        {(!coverImage3Url || coverImage3Url==='' )&&
                        <div  className='flex justify-center items-center w-[100px] h-[160px] rounded border border-dashed cursor-pointer'>
                          <i className="ri-upload-2-line text-[25px]"/>
                        </div>
                        }

                        {coverImage3Url &&
                          <img
                            src={coverImage3Url}
                            alt='gt'
                            className='w-[100px] h-[160px] rounded'
                          />
                        }
                        {coverImage3Url &&
                          <i className="ri-add-line bottom-1 right-1 absolute text-[30px] leading-none bg-white rounded" />
                        }
                      </div>
                    </div>
              </section>
            </div>
          </div>

          <table cellPadding={5} className="w-full table-auto overflow-x-auto mt-2">
            <tbody>
              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">App Type</td>
                <td>
                 <SimpleSelect 
                    placeholder="Select..."
                    value={formData.appType}  
                    className="w-full md:max-w-[300px]"
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        appType: e,
                      })
                    }}
                    options={[{
                      label:'Designer',
                      value: 'designer  '
                    }]} 
                  />
                </td>
              </tr>

              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">Supplier Contact</td>
                <td>
                  <Input
                    value={formData.supplierContact} 
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        supplierContact: e.target.value
                      })
                    }}
                    className="w-full md:max-w-[300px]"
                    theme="default"
                    placeholder="+94123456789"
                  />
                </td>
              </tr>

              <tr>
                <td className="md:w-[200px] w-[90px] poppins-regular text-[16px] text-[#1D1D1F]">Supplier Email</td>
                <td>
                <Input
                    value={formData.supplierEmail} 
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        supplierEmail: e.target.value
                      })
                    }}
                    className="w-full md:max-w-[300px]"
                    theme="default"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="flex flex-col w-full mt-2 px-2">
            <div className="w-full poppins-regular text-[16px] text-[#1D1D1F]">Enter the categories</div>
          </div>

          <div className="flex mt-4 flex-col">
            <div className="flex gap-x-3">
              <Input
                value={formData.cat1[0].name} 
                onChange={(e)=>{
                  setFormData((prevState: any) => ({
                    ...prevState,
                    cat1: [{
                      ...prevState.cat1[0],
                      name: e.target.value
                    }]
                  }));
                }} 
                placeholder="Category name"
                className="w-full md:max-w-[300px]"
                theme="default"
              />

              <Input
                isDisabled={!formData.cat1[0].name}
                value={tempCat1Item} 
                onChange={(e) => setTempCat1Item(e.target.value)}
                placeholder="Category"
                className="w-full md:max-w-[300px]"
                theme="default"
              />

              <Button appearance="outline" className="!border-[2px] !h-[45px]" size='md'
               onClick={() => {
                if (tempCat1Item) {
                  setFormData((prevState: any) => ({
                    ...prevState,
                    cat1: [{
                      ...prevState.cat1[0],
                      data: [...prevState.cat1[0].data, tempCat1Item] // Add new item to data array
                    }]
                  }));
                  setTempCat1Item(''); // Clear the temp data input after adding
                }
              }}
              >Add</Button>
            </div>
            <div className="flex flex-col gap-y-1 mt-2">
              <div className="w-full flex flex-row gap-x-2 justify-start items-center">
              {formData.cat1[0].name && <div className="w-[10px] h-[10px] bg-black rounded-full"></div> }
                <div className="flex text-[12px] poppins-bold">{formData.cat1[0].name}</div>
              </div>
              <div className='flex flex-wrap w-full h-auto gap-2 text-[12px] leading-tight'>
              {formData.cat1[0].data?.map((item:any, idx: number)=>
                  <div key={idx} className='bg-white border border-black rounded-full px-2'>{item} <i className="ri-close-line" 
                  onClick={() => {
                    // Remove the item from the data array by filtering it out
                    setFormData((prevState: any) => ({
                      ...prevState,
                      cat1: [{
                        ...prevState.cat1[0],
                        data: prevState.cat1[0].data.filter((_: any, index: any) => index !== idx) // Filter out the item by index
                      }]
                    }));
                  }}
                  /></div>
              )}

              </div>
            </div>
          </div>

          <div className="flex mt-4 flex-col">
            <div className="flex gap-x-3">
              <Input
                value={formData.cat2[0].name} 
                onChange={(e)=>{
                  setFormData((prevState: any) => ({
                    ...prevState,
                    cat2: [{
                      ...prevState.cat2[0],
                      name: e.target.value
                    }]
                  }));
                }} 
                placeholder="Category name"
                className="w-full md:max-w-[300px]"
                theme="default"
              />

              <Input
                isDisabled={!formData.cat2[0].name}
                value={tempCat2Item} 
                onChange={(e) => setTempCat2Item(e.target.value)}
                placeholder="Category"
                className="w-full md:max-w-[300px]"
                theme="default"
              />

              <Button appearance="outline" className="!border-[2px] !h-[45px]" size='md'
               onClick={() => {
                if (tempCat2Item) {
                  setFormData((prevState: any) => ({
                    ...prevState,
                    cat2: [{
                      ...prevState.cat2[0],
                      data: [...prevState.cat2[0].data, tempCat2Item] // Add new item to data array
                    }]
                  }));
                  setTempCat2Item(''); // Clear the temp data input after adding
                }
              }}
              >Add</Button>
            </div>
            <div className="flex flex-col gap-y-1 mt-2">
              <div className="w-full flex flex-row gap-x-2 justify-start items-center">
              {formData.cat2[0].name &&<div className="w-[10px] h-[10px] bg-black rounded-full"></div> }
                <div className="flex text-[12px] poppins-bold">{formData.cat2[0].name}</div>
              </div>
              <div className='flex flex-wrap w-full h-auto gap-2 text-[12px] leading-tight'>
                {formData.cat2[0].data?.map((item:any, idx: number)=>
                  <div key={idx} className='bg-white border border-black rounded-full px-2'>{item} <i className="ri-close-line" 
                  onClick={() => {
                    // Remove the item from the data array by filtering it out
                    setFormData((prevState: any) => ({
                      ...prevState,
                      cat2: [{
                        ...prevState.cat2[0],
                        data: prevState.cat2[0].data.filter((_: any, index: any) => index !== idx) // Filter out the item by index
                      }]
                    }));
                  }}
                  /></div>
              )}
              </div>
            </div>
          </div>

          <div className="flex mt-4 flex-col">
            <div className="flex gap-x-3">
              <Input
                 value={formData.cat3[0].name} 
                 onChange={(e)=>{
                   setFormData((prevState: any) => ({
                     ...prevState,
                     cat3: [{
                       ...prevState.cat3[0],
                       name: e.target.value
                     }]
                   }));
                 }}
                placeholder="Category name"
                className="w-full md:max-w-[300px]"
                theme="default"
              />

              <Input
               isDisabled={!formData.cat3[0].name}
               value={tempCat3Item} 
               onChange={(e) => setTempCat3Item(e.target.value)}
                placeholder="Category"
                className="w-full md:max-w-[300px]"
                theme="default"
              />

              <Button appearance="outline" className="!border-[2px] !h-[45px]" size='md'
              onClick={() => {
                if (tempCat3Item) {
                  setFormData((prevState: any) => ({
                    ...prevState,
                    cat3: [{
                      ...prevState.cat3[0],
                      data: [...prevState.cat3[0].data, tempCat3Item] // Add new item to data array
                    }]
                  }));
                  setTempCat3Item(''); // Clear the temp data input after adding
                }
              }}
              >Add</Button>
            </div>
            <div className="flex flex-col gap-y-1 mt-2">
              <div className="w-full flex flex-row gap-x-2 justify-start items-center">
              {formData.cat3[0].name && <div className="w-[10px] h-[10px] bg-black rounded-full"></div>}
                <div className="flex text-[12px] poppins-bold">{formData.cat3[0].name}</div>
              </div>
              <div className='flex flex-wrap w-full h-auto gap-2 text-[12px] leading-tight'>
              {formData.cat3[0].data?.map((item:any, idx: number)=>
                  <div key={idx} className='bg-white border border-black rounded-full px-2'>{item} <i className="ri-close-line" 
                    onClick={() => {
                      // Remove the item from the data array by filtering it out
                      setFormData((prevState: any) => ({
                        ...prevState,
                        cat3: [{
                          ...prevState.cat3[0],
                          data: prevState.cat3[0].data.filter((_: any, index: any) => index !== idx) // Filter out the item by index
                        }]
                      }));
                    }}
                  /></div>
                )}
              </div>
            </div>
          </div>

          <div className="flex mt-4 flex-col">
            <div className="flex gap-x-3">
              <Input
                value={formData.cat4[0].name} 
                onChange={(e)=>{
                  setFormData((prevState: any) => ({
                    ...prevState,
                    cat4: [{
                      ...prevState.cat4[0],
                      name: e.target.value
                    }]
                  }));
                }}
                placeholder="Category name"
                className="w-full md:max-w-[300px]"
                theme="default"
              />

              <Input
                isDisabled={!formData.cat4[0].name}
                value={tempCat4Item} 
                onChange={(e) => setTempCat4Item(e.target.value)}
                placeholder="Category"
                className="w-full md:max-w-[300px]"
                theme="default"
              />

              <Button appearance="outline" className="!border-[2px] !h-[45px]" size='md'
               onClick={() => {
                if (tempCat4Item) {
                  setFormData((prevState: any) => ({
                    ...prevState,
                    cat4: [{
                      ...prevState.cat4[0],
                      data: [...prevState.cat4[0].data, tempCat4Item] // Add new item to data array
                    }]
                  }));
                  setTempCat4Item(''); // Clear the temp data input after adding
                }
              }}
              >Add</Button>
            </div>
            <div className="flex flex-col gap-y-1 mt-2">
              <div className="w-full flex flex-row gap-x-2 justify-start items-center">
              {formData.cat4[0].name && <div className="w-[10px] h-[10px] bg-black rounded-full"></div> }
                <div className="flex text-[12px] poppins-bold">{formData.cat4[0].name}</div>
              </div>
              <div className='flex flex-wrap w-full h-auto gap-2 text-[12px] leading-tight'>
                {formData.cat4[0].data?.map((item:any, idx: number)=>
                  <div key={idx} className='bg-white border border-black rounded-full px-2'>{item} <i className="ri-close-line" 
                  onClick={() => {
                    // Remove the item from the data array by filtering it out
                    setFormData((prevState: any) => ({
                      ...prevState,
                      cat4: [{
                        ...prevState.cat4[0],
                        data: prevState.cat4[0].data.filter((_: any, index: any) => index !== idx) // Filter out the item by index
                      }]
                    }));
                  }}
                  /></div>
              )}
              </div>
            </div>
          </div>

          <div className="w-full justify-center items-center flex mt-5">
            <Button appearance="outline" 
              disabled={
                formData.appName === '' || 
                formData.venderName === '' || 
                formData.shortDescription === '' || 
                formData.longDescription === '' || 
                formData.appType.value === '' || 
                formData.supplierContact === '' || 
                formData.supplierEmail === '' || 
               ( formData?.cat1[0]?.data?.length > 0 &&  formData?.cat1[0]?.name === '') ||
               ( formData?.cat2[0]?.data?.length > 0 &&  formData?.cat2[0]?.name === '') ||
               ( formData?.cat3[0]?.data?.length > 0 &&  formData?.cat3[0]?.name === '') ||
               ( formData?.cat4[0]?.data?.length > 0 &&  formData?.cat4[0]?.name === '') || 
               coverImage1Url === '' ||
               coverImage1Url === '' ||
               coverImage1Url === '' ||
               coverImage1Url === '' ||
               appIconUrl === ''
              }
              className="!border-[2px] w-[200px]"
             size='md' 
             isLoading={addNewAppIsLoading}
            onClick={()=>{
              if(isEmail(formData.supplierEmail) && isPhoneNumber(formData.supplierContact)){
                addNewApp()
              }else{
                if(!isPhoneNumber(formData.supplierContact)){
                  notify('Supplier contact number validation failed.', 'error')
                }else{
                  notify('Supplier email validation failed.', 'error')
                }
              }
            }}>Done</Button>
          </div>
        </div>      
      </div>
    </div>
  )
}

export default CreateApp