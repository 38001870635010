import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface DeleteTemplateInterface {
  appID: any
}

export const useDeleteApp = () => {
  const mutate = useMutation(({ 
    appID,
     }: DeleteTemplateInterface) => {
    return postAPI('/external-api/remove_app', {
      appID,
    })
  })

  return mutate
}

export default useDeleteApp
