import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Input } from "components/atoms";
import { useLogin } from "framework/api/methods";


export const Login= () => {
  const navigate = useNavigate();

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  
  const [formData, setFormData] = useState({
    username:'',
    password:''
  })

  
  localStorage.clear()
  

  const { mutate: loginMutate, isLoading: checkUsertIsLoading } = useLogin();

  // function to login
  const handleLogin = () => {
    loginMutate({
      username: formData?.username,
      password: formData?.password
    }, {
      onSuccess: ({ data: successData }: any) => {
        localStorage.setItem('token', successData?.token)
        navigate('/')
      },
      onError: ({ response: { data: errData } }: any) => {
        notify('Login failed. Please try again', 'error')
      },
    })
  }


  return (
    <div className="flex justify-center items-center flex-col w-full">
      <div className="inter-medium text-[38px] leading-tight pb-6 md:pb-10">Admin Login</div>

      <Input 
        className="pb-3 md:pb-5" 
        labelValue='Username' 
        value={formData.username}
        onChange={(e: any)=>{
         setFormData({
           ...formData,
           username: e.target.value
         })
        }}
      />

      <Input 
        className="pb-4 md:pb-8" 
        labelValue='Password'
        type='password'
        value={formData.password}
        onChange={(e: any)=>{
          setFormData({
            ...formData,
            password: e.target.value
          })
        }}
        placeholder='•••••••'
      />

     <Button appearance='outline' 
     disabled={!(formData?.username && formData.password)}
          isLoading={checkUsertIsLoading}
          onClick={() => {
            handleLogin()
          }}>Login</Button>
    </div>
  );
};

export default Login
